import * as d3 from 'd3';
import {
  CharRowWithColor,
  ChartRow,
  ChartTypes,
  colors
} from 'domains/customer/customersList/components/Charts/Charts.types';
import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';

const BarChart = ({
  props,
  type,
  setCategory
}: {
  props: ChartRow[];
  type: ChartTypes;
  setCategory: (label: string) => void;
}) => {
  const [propsWithColor, setPropsWithColor] = useState<CharRowWithColor[]>();
  const [highestAmount, setHighestAmount] = useState<number>(0);
  const [axisLeftValues, setAxisLeftValues] = useState<number[]>();
  const [shouldCut, setShouldCut] = useState<boolean>(false);
  const [indexOfHighestNumber, setIndexOfHighestNumber] = useState<number>();
  const [highestItemColor, setHighestItemColor] = useState<string>('');

  type Lines = {
    legend: number;
    cutted: number;
  };

  const cutLines = (numberOfLines: number): Lines => {
    switch (numberOfLines) {
      case 7:
        return {
          legend: 2,
          cutted: 2
        };
      case 8:
        return {
          legend: 2,
          cutted: 2
        };
      case 9:
        return {
          legend: 2,
          cutted: 2
        };
      case 10:
        return {
          legend: 2,
          cutted: 3
        };
      case 11:
        return {
          legend: 3,
          cutted: 4
        };
      case 13:
        return {
          legend: 3,
          cutted: 4
        };
      default:
        return {
          legend: 3,
          cutted: 4
        };
    }
  };

  const svgRef = useRef(null);
  useEffect(() => {
    const copyOfProps: ChartRow[] = [...props];
    (copyOfProps as CharRowWithColor[]).map((item, index) => {
      //@todo ustalic kolory lub napisac generator kolorow
      item.color = setColor(index);
      item.colorLight = setColor(index, true);
      return item;
    });
    setPropsWithColor(copyOfProps as CharRowWithColor[]);
    const checkHighestAmount = Math.max(...props.map((item) => item.amount));
    setHighestAmount(checkHighestAmount);

    const values = props.map((item) => item.amount);
    let cut = false;
    if (!(values.filter((item) => item === checkHighestAmount).length > 1)) {
      const removeHighest = values.filter((item) => item !== checkHighestAmount);
      const eachNumberThreeTimesSmallerThanHighest = removeHighest.every((val) => val * 3 < checkHighestAmount);
      const someNumberHigherThanZero = removeHighest.some((val) => val > 0);

      if (eachNumberThreeTimesSmallerThanHighest && someNumberHigherThanZero) {
        setShouldCut(true);
        cut = true;
      }
    }

    const highestItemIndex = props.findIndex((el) => el.amount === checkHighestAmount);
    setIndexOfHighestNumber(highestItemIndex);
    calculateTickValues(checkHighestAmount, cut);
    setHighestItemColor(setColor(highestItemIndex));
  }, [props]);

  const setColor = (index: number, light = false) => {
    return index in colors ? (light ? colors[index].colorLight : colors[index].color) : '#000000';
  };

  const wrapText = (text: any, width: any) => {
    text.each(function () {
      //@ts-ignore
      let text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line: string[] = [],
        lineNumber = 0,
        lineHeight = 1,
        y = text.attr('y'),
        dy = parseFloat(text.attr('dy')),
        tspan = text
          .text(null)
          .append('tspan')
          .attr('font-size', '0.8rem')
          .attr('font-weight', '500')
          .attr('x', 0)
          .attr('y', 24)
          .attr('dy', dy + 'em');

      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(' '));
        //@ts-ignore
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = text
            .append('tspan')
            .attr('font-size', '0.8rem')
            .attr('font-weight', '500')
            .attr('x', 0)
            .attr('y', parseInt(y) + 24)
            .attr('dy', `${++lineNumber * lineHeight + dy}em`)
            .text(word);
        }
      }

      if (lineNumber > 0) {
        const startDy = -(lineNumber * (lineHeight / 6));
        text
          .selectAll('tspan')
          .attr('font-size', '0.8rem')
          .attr('font-weight', '500')
          .attr('dy', (d, i) => startDy + lineHeight * i + 'em');
      }
    });
  };

  const calculateTickValues = (highestAmount: number, shouldCut: boolean): number[] => {
    const values: number[] = [0];
    const splitedNumber = Math.round(highestAmount).toString().split('');
    const newNumber = splitedNumber.map((char: string, index: number) => {
      if (index === 0) {
        return Number(char);
      } else if (index === 1) {
        return Number(char);
      } else {
        return 0;
      }
    });

    const smallerThanHighestAmount = Number(newNumber.join(''));
    const oneFifth = Math.ceil(smallerThanHighestAmount / 5);
    const checkHighestAmount = Math.max(...props.map((item) => item.amount));

    let i = 1;
    let addNextValues = true;
    while (addNextValues) {
      const nextValue = oneFifth * i;
      if (nextValue > checkHighestAmount || checkHighestAmount === 0) {
        addNextValues = false;
      }
      values.push(oneFifth * i);
      i++;
    }

    if (shouldCut) {
      setHighestAmount(checkHighestAmount);
    } else {
      setHighestAmount(values[values.length - 1]);
    }

    setAxisLeftValues(values);
    return values;
  };

  useEffect(() => {
    if (propsWithColor) {
      const svg = d3.select(svgRef.current);
      const marginWidth = 80;
      const marginHeight = 40;
      const width = 1200 - 2 * marginWidth;
      const height = 400 - 2 * marginHeight;
      const onlyZeros = props.every((val) => val.amount < 1);

      const chart = svg
        .append('g')
        .attr('class', 'mainG')
        .attr('transform', `translate(${marginWidth}, ${marginHeight})`);

      const xScale = d3
        .scaleBand()
        .range([0, width])
        .domain(props.map((s) => s.label));

      const yScale = d3.scaleLinear().range([height, 0]).domain([0, highestAmount]);

      //@ts-ignore
      const makeYLines = () => d3.axisLeft(yScale).scale(yScale);

      const t2 = chart
        .append('g')
        .attr('transform', `translate(0, ${height})`)
        .attr('class', 'axisB')
        .call(d3.axisBottom(xScale).tickSize(0));

      const t3 = t2.selectAll('.tick text').call(wrapText, xScale.bandwidth());

      t2.selectAll('.axisB path').attr('opacity', '0');

      t2.selectAll('.axisB .tick')
        .append('rect')
        .attr('fill', '#fff')
        .attr('transform', 'translate(-50, 0)')
        .attr('rx', 5)
        .attr('x', 0)
        .attr('y', 50)
        .attr('width', '0')
        .attr('height', '0');

      const ticks = Array.from(new Set(calculateTickValues(highestAmount, shouldCut)));

      chart
        .append('g')
        .attr('class', 'yGrid')
        .call(
          d3
            .axisLeft(yScale)
            .tickValues(ticks)
            .tickPadding(15)
            // .ticks(6)
            .tickFormat((value, index) => {
              if (shouldCut) {
                if (axisLeftValues && index === axisLeftValues.length - 3) {
                  return '';
                }
              }
              return type === ChartTypes.CUSTOMERS_LIST ? `${value}` : `${Number(value) / 1000}`;
            })
            .tickSize(0)
        );

      if (onlyZeros) {
        chart.selectAll('.yGrid .tick').attr('transform', () => 'translate(0,320)');
      }

      chart.selectAll('.yGrid .domain').attr('opacity', '0.2');

      chart
        .append('g')
        .attr('class', 'grid')
        .attr('opacity', '0.2')
        .attr('transform', `translate(-8, 0)`)
        .call(
          makeYLines()
            .tickSize(-width)
            .tickFormat((d) => '')
        );

      chart
        .selectAll('.grid .tick')
        .style('stroke-dasharray', (d, i, el) => (i === 0 || i === el.length - 1 ? '' : '2 2'));

      const numOfLines = document.querySelectorAll('.grid .tick').length - 1;

      if (onlyZeros) {
        //@ts-ignore
        chart.selectAll('.grid .tick').attr('transform', () => 'translate(8, 0)');
      } else {
        //@ts-ignore
        chart
          .selectAll('.grid .tick')
          //@ts-ignore
          .attr('transform', (d, i, el) => `${el[i].attributes.transform.value}, scale(1.008)`);
      }

      chart.selectAll('.grid .domain').attr('display', 'none');

      const barGroups = chart.selectAll().data(propsWithColor).enter().append('g').attr('class', 'barContainer');

      const newGroup = barGroups
        .append('g')
        .attr('class', 'barContainer2')
        .attr('fill', (data) => data.color);

      newGroup
        .append('rect')
        .attr('width', 1)
        //@ts-ignore
        .attr('x', (g) => xScale(g.label) + xScale.bandwidth())
        .attr('y', 0)
        .attr('height', height)
        .attr('class', 'lineX')
        .attr('fill', 'rgba(0, 0, 0, 0.2)');

      let heightOfHeighest: number = 0;

      if (!onlyZeros) {
        newGroup
          .append('rect')
          .attr('class', 'barMain')
          .attr('opacity', 0.2)
          .attr('height', (g, index) => {
            if (indexOfHighestNumber === index && shouldCut) {
              heightOfHeighest = height - yScale(g.amount);
              return (heightOfHeighest / numOfLines) * (numOfLines - cutLines(numOfLines).legend);
            }
            return height - yScale(g.amount);
          })
          //@ts-ignore
          .attr('x', (g) => xScale(g.label))
          .attr('y', (g, index) => {
            if (indexOfHighestNumber === index && shouldCut) {
              return yScale(g.amount) + (heightOfHeighest / numOfLines) * cutLines(numOfLines).legend;
            }
            return yScale(g.amount);
          })
          .attr('width', xScale.bandwidth());

        newGroup
          .append('rect')
          .attr('fill', '#000')
          .attr('class', 'barInside')
          //@ts-ignore
          .attr('x', (g) => xScale(g.label))
          .attr('y', (g, index) => {
            if (indexOfHighestNumber === index && shouldCut) {
              return yScale(g.amount) + (heightOfHeighest / numOfLines) * cutLines(numOfLines).legend;
            }
            return yScale(g.amount);
          })
          .attr('height', (g, index) => {
            if (indexOfHighestNumber === index && shouldCut) {
              return (heightOfHeighest / numOfLines) * (numOfLines - cutLines(numOfLines).legend);
            }
            return height - yScale(g.amount);
          })
          .attr('width', xScale.bandwidth() / 4)
          .attr('opacity', 1)
          .attr('fill', (data) => data.color)
          .attr('transform', `translate(${(xScale.bandwidth() / 4) * 1.5}, 0)`);

        newGroup
          // .selectAll('.barContainer2')
          .append('rect')
          .attr('class', 'lineTop')
          //@ts-ignore
          .attr('fill', (data) => data.color)
          .attr('height', 1)
          //@ts-ignore
          .attr('x', (g) => xScale(g.label))
          .attr('y', (g, index) => {
            if (indexOfHighestNumber === index && shouldCut) {
              return yScale(g.amount) + (heightOfHeighest / numOfLines) * cutLines(numOfLines).legend;
            }
            return yScale(g.amount);
          })
          //@ts-ignore
          .attr('width', xScale.bandwidth());
      }

      barGroups
        .selectAll('.barContainer2')
        .append('rect')
        .attr('class', 'lineBottom')
        //@ts-ignore
        .attr('fill', (data) => data.color)
        .attr('height', 1)
        //@ts-ignore
        .attr('x', (g) => xScale(g.label))
        .attr('y', height)
        .attr('width', xScale.bandwidth());

      barGroups
        .selectAll('.barContainer2')
        .append('circle')
        .attr('class', 'circleBottom')
        //@ts-ignore
        .attr('cx', (a) => xScale(a.label))
        .attr('cy', height)
        .attr('r', 4)
        .attr('fill', '#ffffff')
        .style('fill', '#ffffff')
        .style('stroke', '#cecece');

      barGroups
        .append('circle')
        .attr('class', 'circleBottomLast')
        .attr('cx', width)
        .attr('cy', height)
        .attr('r', 4)
        .style('fill', '#ffffff')
        .style('stroke', '#cecece');

      const gbWidth = 70;

      barGroups
        .append('g')
        .attr('class', 'gB')
        .append('rect')
        .attr('fill', (data) => data.colorLight)
        .attr('transform', `translate(-${gbWidth / 2}, -16)`)
        .attr('rx', 5)
        .attr('style', (data) => `outline: thin solid ${data.color}; border-radius: 4px`)
        .attr('width', `${gbWidth}px`)
        .attr('height', '25px')
        //@ts-ignore
        .attr('x', (a) => xScale(a.label) + xScale.bandwidth() / 2)
        .attr('y', (g, index) => {
          if (indexOfHighestNumber === index && shouldCut) {
            return yScale(g.amount) + (heightOfHeighest / numOfLines) * cutLines(numOfLines).legend - 20;
          }
          if (onlyZeros) {
            return yScale(g.amount) * 2 - 20;
          }
          return yScale(g.amount) - 20;
        });

      barGroups
        .append('text')
        .attr('class', 'value')
        .data(propsWithColor)
        //@ts-ignore
        .attr('x', (a) => xScale(a.label) + xScale.bandwidth() / 2)
        .attr('y', (g, index) => {
          if (indexOfHighestNumber === index && shouldCut) {
            return yScale(g.amount) + (heightOfHeighest / numOfLines) * cutLines(numOfLines).legend - 20;
          }
          if (onlyZeros) {
            return yScale(g.amount) * 2 - 20;
          }
          return yScale(g.amount) - 20;
        })
        .attr('text-anchor', 'middle')
        .text((a) =>
          type === ChartTypes.CUSTOMERS_LIST ? a.amount : `${(a.amount / 1000).toFixed(2).replace('.', ',')}`
        );

      svg
        .append('text')
        .attr('class', 'title')
        .attr('x', type === ChartTypes.CUSTOMERS_LIST ? 5 : 70)
        .attr('y', 20)
        .attr('text-anchor', 'left')
        .text(type === ChartTypes.CUSTOMERS_LIST ? 'Liczba klientów' : 'tys. PLN');

      if (shouldCut) {
        chart.selectAll('.yGrid .tick').call((el) => {
          // @ts-ignore
          const length = el._groups[0].length;
          //@ts-ignore
          const cuttedValue = el._groups[0][length - 3].getAttribute('transform');
          //@ts-ignore
          const firstAfterCutted = el._groups[0][length - 2].getAttribute('transform');
          //@ts-ignore
          el._groups[0][length - 1].setAttribute('transform', firstAfterCutted);
          //@ts-ignore
          el._groups[0][length - 2].setAttribute('transform', cuttedValue);
        });

        const cuttedLineContainer = chart.append('g').attr('class', 'cuttedLineContainer');

        cuttedLineContainer
          .append('rect')
          .attr('class', 'cutted')
          .attr('opacity', '1')
          .attr('width', '1042')
          .attr('height', 8)
          //@ts-ignore
          .attr('fill', '#fff')
          //@ts-ignore
          .attr('x', '-1')
          .attr('y', `${(heightOfHeighest / numOfLines) * cutLines(numOfLines).cutted + 10}`);

        const cuttedTopGroup = cuttedLineContainer
          .append('g')
          .attr('x', '-1')
          // .attr('y', '109')
          .attr('y', `${(heightOfHeighest / numOfLines) * cutLines(numOfLines).cutted + 9}`)
          .attr('width', '1042')
          .attr('position', 'relative');

        cuttedTopGroup
          .append('rect')
          .attr('class', 'cuttedTop')
          .attr('opacity', '1')
          .attr('width', '1042')
          .attr('height', 1)
          .attr('fill', '#cecece')
          .attr('x', '-1')
          .attr('y', '109')
          //@ts-ignore
          .attr('y', `${(heightOfHeighest / numOfLines) * cutLines(numOfLines).cutted + 9}`);

        cuttedLineContainer
          .append('rect')
          .attr('class', 'cuttedBottom')
          .attr('opacity', '1')
          .attr('width', '1042')
          .attr('height', 1)
          .attr('fill', '#cecece')
          .attr('x', '-1')
          .attr('y', '118')
          //@ts-ignore
          .attr('y', `${(heightOfHeighest / numOfLines) * cutLines(numOfLines).cutted + 18}`);

        cuttedTopGroup
          .append('rect')
          .attr('class', 'cutLeftTop')
          .attr('opacity', '1')
          .attr('width', '10px')
          .attr('height', 1)
          .attr('fill', '#cecece')
          .attr('x', '-11')
          .attr(
            'transform',
            (d, i) => `translate(0, ${(heightOfHeighest / numOfLines) * cutLines(numOfLines).cutted + 10}) rotate(45)`
          );

        cuttedTopGroup
          .append('rect')
          .attr('class', 'cutLeftBottom')
          .attr('opacity', '1')
          .attr('width', '10px')
          .attr('height', 1)
          .attr('fill', '#cecece')
          .attr('x', '-11')
          .attr(
            'transform',
            (d, i) => `translate(0, ${(heightOfHeighest / numOfLines) * cutLines(numOfLines).cutted + 17}) rotate(-45)`
          );

        cuttedTopGroup
          .append('rect')
          .attr('class', 'cutRightTop')
          .attr('opacity', '1')
          .attr('width', '10px')
          .attr('height', 1)
          .attr('fill', '#cecece')
          .attr('x', '0')
          .attr(
            'transform',
            (d, i) =>
              `translate(1040, ${(heightOfHeighest / numOfLines) * cutLines(numOfLines).cutted + 9.5}) rotate(-45)`
          );

        cuttedTopGroup
          .append('rect')
          .attr('class', 'cutLeftBottom')
          .attr('opacity', '1')
          .attr('width', '10px')
          .attr('height', 1)
          .attr('fill', '#cecece')
          .attr('x', '-11')
          .attr(
            'transform',
            (d, i) =>
              `translate(1040, ${(heightOfHeighest / numOfLines) * cutLines(numOfLines).cutted + 18}) rotate(-135)`
          );

        const linesGroup = cuttedLineContainer
          .append('g')
          .attr('width', xScale.bandwidth)
          .attr('height', 10)
          .attr('x', '0')
          .attr('class', styles.linesGroup);

        linesGroup.call((el) => {
          const cutPosition = 104;
          const calcultePosition = indexOfHighestNumber ? cutPosition * indexOfHighestNumber : 0;

          cuttedLineContainer
            .append('rect')
            .attr('class', 'cuttedBottom')
            .attr('opacity', '1')
            .attr('width', xScale.bandwidth)
            .attr('height', 1)
            .attr('fill', highestItemColor)
            .attr('x', calcultePosition)
            .attr('y', `${(heightOfHeighest / numOfLines) * cutLines(numOfLines).cutted + 18}`);

          cuttedLineContainer
            .append('rect')
            .attr('class', 'cuttedBottom')
            .attr('opacity', '1')
            .attr('width', xScale.bandwidth)
            .attr('height', 1)
            .attr('fill', highestItemColor)
            .attr('x', calcultePosition)
            .attr('y', `${(heightOfHeighest / numOfLines) * cutLines(numOfLines).cutted + 9}`);

          for (let i = 4; i < 116; i += 6) {
            let position = cutPosition ? calcultePosition + i : 0;

            linesGroup
              .append('rect')
              .attr('class', styles.lineNr1)
              .attr('opacity', '1')
              .attr('width', 20)
              .attr('height', 1)
              .attr('fill', highestItemColor)
              .attr(
                'transform',
                (d) =>
                  `translate(${position}, ${
                    (heightOfHeighest / numOfLines) * cutLines(numOfLines).cutted + 10
                  }) rotate(135)`
              );
          }
        });
      }
    }
  }, [propsWithColor]);

  const handleSetCategory = (data: ChartRow) => {
    const currentLabel = data.label;
    const label = currentLabel === 'Brak kategorii' ? 'X' : currentLabel;
    return setCategory(label.replaceAll('Kategoria ', ''));
  };

  return (
    <div id={styles.layout}>
      <div id={styles.container}>
        <svg ref={svgRef} className={styles.svg}></svg>
      </div>
      <div className={styles.clientsContainer}>
        {props.map((el) => (
          <div className={styles.client} key={el.label} onClick={() => handleSetCategory(el)}>
            {el.count} klientów
          </div>
        ))}
      </div>
    </div>
  );
};

export default BarChart;
