import React, { useState, useEffect, MouseEvent, useCallback, useRef, useMemo } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import tableClasses from '../../../styles/table.module.scss';
import { isEqual } from 'lodash';
import { useAuth } from 'oidc-react';
import { useFormik } from 'formik';
import {
  getCustomers,
  enableMonitoring,
  getCompaniesDict,
  getFilterConfigurations,
  disableMonitoring,
  exportToCsv
} from 'api/services';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import {
  Tooltip as CustomTooltip,
  Paper,
  Breadcrumbs,
  Header,
  Pagination,
  SearchField,
  Scrollbar,
  Filter,
  Dialog,
  StyledButton,
  StyledCheckbox,
  CheckMonitorDialog,
  CheckboxGroup,
  MultiCheckbox
} from 'components';
import { ROLE } from 'config/oidc';
import {
  CUSTOMER_FIELDS,
  CustomerFieldType,
  CounterPartiesType,
  monitorClientCheckboxes,
  paymentCredibility,
  lastEvent,
  lastAddedDebt,
  RADIOGROUP_VALUES,
  ACTION_TYPES,
  CustomerFormikValues,
  tradeCreditLimitFilters,
  changeCredibilityRadio,
  changeLimitRadio,
  TRADE_CREDIT_LIMIT_FILTER_VALUES,
  TRADE_CREDIT_LIMIT_FILTER,
  TRADE_CREDIT_LIMIT_OPTIONS
} from '../customersList/customersList.types';
import { TableHead, FilterSwitch, RangeSlider, Charts, TableCells, Radio } from '../customersList/components';
import { initialValues, TOOLTIPS, ACTIONS } from './config';
import {
  createRows,
  createFiltersQuery,
  createSearchQuery,
  mapOrderBy,
  createExportToCsvSearchFilters
} from '../customersList/utils';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { HeadCell } from '../customersList/components/TableHead.types';

import FormControlLabel from '@mui/material/FormControlLabel';
import {
  CounterpartySearchItemDto,
  CustomersDto,
  DictionaryDto,
  ExportCounterpartiesDto,
  MONITORING_STATE,
  RiskGroupItemDto
} from 'api/models';
import {
  useFetch,
  useSendRequest,
  useDidUpdateEffect,
  useGoToFirstPage,
  useRowsPerPage,
  useFormikFilters,
  useCheckMonitoring
} from 'lib/hooks';
import { PATH } from 'router/routes';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import DateAdapter from '@mui/lab/AdapterMoment';
import TextField from '@mui/material/TextField';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FiltersTop } from 'domains/customer/shared';
import { mapSortDirection, Order } from 'types';
import SideNav from 'components/SideNav';
import classes from '../../../App.module.scss';
import { FIELDS } from '../shared/types/clientForm.types';
import { ChartLabels } from './components/Charts/Charts.types';
// @ts-ignore
import { useDebounce } from 'use-lodash-debounce';
import moment from 'moment';
import { SelectChangeEvent } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { getMonitoringCounterThunk } from 'store/monitoringCounter';
import { useDispatch } from 'react-redux';
import { LOCAL_STORAGE_ACTIONS } from 'components/ActivityDetector/ActivityDetector.types';
import { tooltipImportInProgress } from '../customerProfile';
import { ButtonWithTooltip } from 'components';

const breadcrumbs = [{ label: 'Lista klientów' }];

const tooltipExportText =
  'Wyeksportowane zostaną dane o klientach uwzględnionych na liście wyników w danej chwili, z uwzględnieniem filtrów, wyszukiwania, sortowania i wszystkich stron.';
const tooltipSelectedItemsText =
  'Sposób działania akcji grupowych oraz skutki ich wykonania opisane są szerzej w Samouczku w punkcie 1.4';
const tooltipFilterstText = 'Filtry służą do zawężania listy klientów. Filtry działają jednocześnie.';
const tooltipFilterstLink = { linkName: 'Samouczek, punkt 1.1', linkUrl: '/tutorial#punkt-1-1' };
const tooltipMonitoringClientsLink = { linkName: 'Samouczek, punkt 3.1.', linkUrl: '/tutorial#punkt-3-1' };
const tooltipCredibilityLink = { linkName: 'Samouczek, punkt 4.1.', linkUrl: '/tutorial#punkt-4-1' };
const tooltipDebtLink = { linkName: 'Samouczek, punkt 3.1.', linkUrl: '/tutorial#punkt-3-1' };
const tooltipReportsLink = { linkName: 'Samouczek, punkt 5.1.', linkUrl: '/tutorial#punkt-5-1' };
const tooltipClientsListLink = { linkName: 'Samouczek, punkt 1.2.', linkUrl: '/tutorial#punkt-1-2' };
const tooltipSaleLink = { linkName: 'Samouczek, punkt 2.2.', linkUrl: '/tutorial#punkt-2-2' };
const tooltipLastDebtLink = { linkName: 'Samouczek, punkt 3.1.', linkUrl: '/tutorial#punkt-3-1' };
const tooltipSelectedItemsLink = { linkName: 'Samouczek, punkt 1.4.', linkUrl: '/tutorial#punkt-1-4' };
const tooltipChangeCredibilityLink = { linkName: 'Samouczek, punkt 4.1', linkUrl: '/tutorial#punkt-4-1' };
const tooltipChangeLimitLink = { linkName: 'Samouczek, punkt 4.2', linkUrl: '/tutorial#punkt-4-2' };
const tooltipTradeCreditLimitLink = { linkName: 'Samouczek, punkt 4.2', linkUrl: '/tutorial#punkt-4-2' };

const CustomersList = () => {
  const savedOrder = localStorage.getItem('customerOrder');
  const savedOrderBy = localStorage.getItem('customerOrderBy');
  const dispatch = useDispatch();
  const listHeaderRef = useRef<null | HTMLDivElement>(null);

  const rowsPerPage = useRowsPerPage();
  const [sessionFilters, setSessionFilters] = useFormikFilters('customerFilterValues');
  const [order, setOrder] = useState<Order>((savedOrder as Order) ?? 'asc');
  const [orderBy, setOrderBy] = useState<CUSTOMER_FIELDS>((savedOrderBy as CUSTOMER_FIELDS) ?? CUSTOMER_FIELDS.NAME);
  const [filtersLoaded, setFiltersLoaded] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(rowsPerPage);
  const [selectedCustomers, setSelectedCustomers] = useState<Set<string>>(new Set());
  const [selectedMonitoringIds, setSelectedMonitoringIds] = useState<Set<string>>(new Set());
  const [selectedMonitoringStates, setSelectedMonitoringStates] = useState<Map<string, MONITORING_STATE | undefined>>(
    new Map()
  );
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [isCheck, setIsCheck] = useState<string[]>([]);
  const [isDefaultValues, setIsDefaultValues] = useState<boolean>(true);
  const [maxDebtValue, setMaxDebtValue] = useState<number>(0);
  const [maxGrossDebt, setMaxGrossDebt] = useState<number>(0);
  const [maxGrossSale, setMaxGrossSale] = useState<number>(0);
  const [minGrossDebt, setMinGrossDebt] = useState<number>(0);
  const [minGrossSale, setMinGrossSale] = useState<number>(0);
  const [maxTradeCreditLimit, setMaxTradeCreditLimit] = useState<number>(0);
  const [riskGroups, setRiskGroups] = useState<RiskGroupItemDto[]>([]);
  const [ignoreCustomerFetch, setIgnoreCustomerFetch] = useState<boolean>(true);
  const [currentCompany, setCurrentCompany] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [customersData, setCustomersData] = useState<CustomersDto | null>();
  const [customersResult, setCustomersResult] = useState<CounterpartySearchItemDto[]>();
  const [date, setDate] = useState<Date | null>(new Date());
  const [isSelectedDisable, setIsSelectedDisable] = useState<boolean>(false);
  const [counterPartiesStatus, setCounterPartiesStatus] = useState<CounterPartiesType[]>([]);
  const [currentInterval, setCurrentInterval] = useState<any>({ intervalId: null });
  const [selectAllCheckboxChecked, setSelectAllCheckboxChecked] = useState(false);
  const [isLoadingResults, setIsLoadingResults] = useState(true);
  const [selectedTaxIdentificationNumber, setSelectedTaxIdentificationNumber] = useState('');
  const [actionType, setActionType] = useState('');
  const [action, setAction] = useState('');
  const [earliestDate, setEarliestDate] = useState('');
  const [handleClearValueCounter, setHandleClearValueCounter] = useState(0);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [monitoringTaxId, setMonitoringTaxId] = useState('');
  const [firstDictCompany, setFirstDictCompany] = useState('');
  const [isInitialRequest, setIsInitialRequest] = useState(true);
  const [companyChanged, setCompanyChanged] = useState(false);
  const initialSettingFilters = useRef(false);

  const { canEnable, canDisable, isOpen, cancelAction, openModal } = useCheckMonitoring();

  const { goToFirstPage } = useGoToFirstPage();

  const navigate = useNavigate();
  const pageNumber = searchParams.get('page');

  const customerFilterValues = localStorage.getItem(LOCAL_STORAGE_ACTIONS.CUSTOMER_FILTER_VALUES);
  const curatorSelectedCompanyId = localStorage.getItem(LOCAL_STORAGE_ACTIONS.CURATOR_SELECTED_COMPANY_ID);
  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;

  const companyId = useMemo(
    () =>
      userRole === ROLE.CURATOR && curatorSelectedCompanyId
        ? curatorSelectedCompanyId
        : auth?.userData?.profile?.Company,
    []
  );
  const hasMonitoringPermission = auth?.userData?.profile?.HasClientMonitoringPermission;
  const { result: resultRequest, sendData } = useSendRequest();
  const { result: exportDataResult, sendData: sendExportRequest, isLoading: isLoadingExportToCsv } = useSendRequest();

  const { result: companiesDict } = useFetch<DictionaryDto>(getCompaniesDict, [], ![ROLE.ADMIN_KG].includes(userRole));

  useEffect(() => {
    if (userRole === ROLE.ADMIN_KG && companiesDict && companiesDict?.dictionary?.length > 0) {
      const firstCompany = companiesDict.dictionary[0].id;
      setFirstDictCompany(firstCompany);
      formik.setFieldValue(CUSTOMER_FIELDS.COMPANY_ID, firstCompany);
    }
  }, [companiesDict]);

  useEffect(() => {
    if (companyId) {
      setCurrentCompany(companyId);
    }
  }, [companyId]);

  const setFilterNumber = (value: number, callback: Function) => {
    callback(value ? Number(value) : 0);
  };

  const { result: filters } = useFetch<any>(
    useCallback(() => getFilterConfigurations(currentCompany), [currentCompany]),
    [currentCompany],
    !currentCompany
  );

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      ...initialValues,
      [CUSTOMER_FIELDS.NAME]: searchParams.get(CUSTOMER_FIELDS.NAME) || initialValues[CUSTOMER_FIELDS.NAME],
      [CUSTOMER_FIELDS.TAX_NUMBER]:
        searchParams.get(CUSTOMER_FIELDS.TAX_NUMBER) || initialValues[CUSTOMER_FIELDS.TAX_NUMBER],
      [CUSTOMER_FIELDS.DESCRIPTION1]:
        searchParams.get(CUSTOMER_FIELDS.DESCRIPTION1) || initialValues[CUSTOMER_FIELDS.DESCRIPTION1],
      [CUSTOMER_FIELDS.DESCRIPTION2]:
        searchParams.get(CUSTOMER_FIELDS.DESCRIPTION2) || initialValues[CUSTOMER_FIELDS.DESCRIPTION2]
    },
    onSubmit: () => {}
  });

  useEffect(() => {
    sessionFilters && formik.setValues({ ...formik.values, ...JSON.parse(sessionFilters) });
  }, []);

  const {
    Name,
    Report,
    MonitorClient,
    DebtValueFrom,
    DebtValueTo,
    PaymentCredibility,
    CompanyId,
    LastDebt,
    LastEvent,
    RiskGroupCodes,
    GrossDebtFrom,
    GrossDebtTo,
    GrossSaleLast12MonthsFrom,
    GrossSaleLast12MonthsTo,
    TradeCreditLimitCheckboxValueFrom,
    TradeCreditLimitCheckboxValueTo,
    ChangeCredibility,
    ChangeLimit,
    TradeCreditLimitCheckboxValue,
    isEditable
  } = formik.values;

  const {
    Name: name,
    description1: desc1,
    description2: desc2,
    taxIdentificationNumber: tax,
    ...restFormikValues
  } = formik.values;

  useDidUpdateEffect(() => {
    const values = JSON.stringify(restFormikValues);

    if (values !== JSON.stringify(sessionFilters)) {
      const parsedSessionFilters = JSON.parse(sessionFilters || '{}');
      const filtersWhenCompanyChanged = companyChanged
        ? {
            [CUSTOMER_FIELDS.DEBT_VALUE_TO]: maxDebtValue,
            [CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_FROM]: minGrossSale,
            [CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_TO]: maxGrossSale,
            [CUSTOMER_FIELDS.GROSS_DEBT_FROM]: minGrossDebt,
            [CUSTOMER_FIELDS.GROSS_DEBT_TO]: maxGrossDebt,
            [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_TO]: maxTradeCreditLimit
          }
        : {};
      const sessionFiltersWithSearch = JSON.stringify({
        ...parsedSessionFilters,
        ...restFormikValues,
        ...filtersWhenCompanyChanged
      });
      if (sessionFiltersWithSearch !== '{}') {
        setSessionFilters(sessionFiltersWithSearch);
        if (initialSettingFilters.current && companyChanged) {
          setCompanyChanged(false);
          initialSettingFilters.current = false;
        } else if (companyChanged) {
          initialSettingFilters.current = true;
        }
      }
    }

    const emptySearchParams = {
      [CUSTOMER_FIELDS.NAME]: '',
      [CUSTOMER_FIELDS.TAX_NUMBER]: '',
      [CUSTOMER_FIELDS.DESCRIPTION1]: '',
      [CUSTOMER_FIELDS.DESCRIPTION2]: ''
    };

    const initialValuesWithEmptySearchParams = {
      ...initialValues,
      ...emptySearchParams,
      [CUSTOMER_FIELDS.DEBT_VALUE_TO]: maxDebtValue,
      [CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_FROM]: minGrossSale,
      [CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_TO]: maxGrossSale,
      [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_TO]: maxTradeCreditLimit,
      [CUSTOMER_FIELDS.GROSS_DEBT_FROM]: minGrossDebt,
      [CUSTOMER_FIELDS.GROSS_DEBT_TO]: maxGrossDebt,
      [CUSTOMER_FIELDS.LAST_DEBT]: '0',
      [CUSTOMER_FIELDS.LAST_EVENT]: '0',
      [CUSTOMER_FIELDS.CHANGE_CREDIBILITY]: '0',
      [CUSTOMER_FIELDS.CHANGE_LIMIT]: '0',
      [CUSTOMER_FIELDS.COMPANY_ID]: firstDictCompany
    };

    const formikValuesWithEmptySearchParams = {
      ...initialValues,
      ...formik.values,
      ...emptySearchParams,
      [CUSTOMER_FIELDS.LAST_DEBT]: formik.values[CUSTOMER_FIELDS.LAST_DEBT]?.toString() ?? '0',
      [CUSTOMER_FIELDS.LAST_EVENT]: formik.values[CUSTOMER_FIELDS.LAST_EVENT]?.toString() ?? '0',
      [CUSTOMER_FIELDS.CHANGE_CREDIBILITY]: formik.values[CUSTOMER_FIELDS.CHANGE_CREDIBILITY]?.toString() ?? '0',
      [CUSTOMER_FIELDS.CHANGE_LIMIT]: formik.values[CUSTOMER_FIELDS.CHANGE_LIMIT]?.toString() ?? '0',
      [CUSTOMER_FIELDS.COMPANY_ID]: formik.values[CUSTOMER_FIELDS.COMPANY_ID]
    };

    setIsDefaultValues(isEqual(initialValuesWithEmptySearchParams, formikValuesWithEmptySearchParams));
  }, [formik.values]);

  const setCategory = (label: string) => {
    let results;
    switch (label) {
      case ChartLabels.MonitoringDisabled:
        results = {
          [CUSTOMER_FIELDS.MONITOR_CLIENT]: 0,
          [CUSTOMER_FIELDS.MONITOR_CLIENT_ENABLED]: false,
          [CUSTOMER_FIELDS.MONITOR_CLIENT_DISABLED]: true,
          [CUSTOMER_FIELDS.RISK_GROUP_CODES]: ''
        };
        break;
      case ChartLabels.NoCategory:
        results = {
          [CUSTOMER_FIELDS.MONITOR_CLIENT]: 2,
          [CUSTOMER_FIELDS.MONITOR_CLIENT_ENABLED]: false,
          [CUSTOMER_FIELDS.MONITOR_CLIENT_DISABLED]: false,
          [CUSTOMER_FIELDS.RISK_GROUP_CODES]: 'X'
        };
        break;
      default:
        results = {
          [CUSTOMER_FIELDS.MONITOR_CLIENT]: 2,
          [CUSTOMER_FIELDS.MONITOR_CLIENT_ENABLED]: false,
          [CUSTOMER_FIELDS.MONITOR_CLIENT_DISABLED]: false,
          [CUSTOMER_FIELDS.RISK_GROUP_CODES]: label
        };
    }

    formik.setValues({
      ...formik.values,
      [CUSTOMER_FIELDS.MONITORING_STATE]: '',
      [CUSTOMER_FIELDS.REPORT]: false,
      [CUSTOMER_FIELDS.PAYMENT_CREDIBILITY]: 2,
      [CUSTOMER_FIELDS.PAYMENT_CREDIBILITY_DEBTOR]: false,
      [CUSTOMER_FIELDS.PAYMENT_CREDIBILITY_NON_DEBTOR]: false,
      [CUSTOMER_FIELDS.DEBT_VALUE_FROM]: 0,
      [CUSTOMER_FIELDS.DEBT_VALUE_TO]: maxDebtValue,
      [CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_FROM]: minGrossSale,
      [CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_TO]: maxGrossSale,
      [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_TO]: maxTradeCreditLimit,
      [CUSTOMER_FIELDS.GROSS_DEBT_FROM]: minGrossDebt,
      [CUSTOMER_FIELDS.GROSS_DEBT_TO]: maxGrossDebt,
      [CUSTOMER_FIELDS.SCORING]: '',
      ...results
    });
  };

  const handleTradeCreditLimitFilter = useMemo(() => {
    if (TradeCreditLimitCheckboxValue?.length === 2) {
      return TRADE_CREDIT_LIMIT_FILTER.Both;
    }
    if (TradeCreditLimitCheckboxValue?.length === 0) {
      return TRADE_CREDIT_LIMIT_FILTER.None;
    }
    if (
      TradeCreditLimitCheckboxValue?.length === 1 &&
      TradeCreditLimitCheckboxValue?.[0] === TRADE_CREDIT_LIMIT_OPTIONS.NotCalculated
    ) {
      return TRADE_CREDIT_LIMIT_FILTER.NotCalculated;
    }
    if (
      TradeCreditLimitCheckboxValue?.length === 1 &&
      TradeCreditLimitCheckboxValue?.[0] === TRADE_CREDIT_LIMIT_OPTIONS.Calculated
    ) {
      return TRADE_CREDIT_LIMIT_FILTER.Calculated;
    }
    return TRADE_CREDIT_LIMIT_FILTER.None;
  }, [TradeCreditLimitCheckboxValue]);

  const tradeCreditLimitFromTo = useMemo(() => {
    if (
      TradeCreditLimitCheckboxValue?.length === 1 &&
      TradeCreditLimitCheckboxValue?.[0] === TRADE_CREDIT_LIMIT_OPTIONS.Calculated
    ) {
      return `&TradeCreditLimitFrom=${TradeCreditLimitCheckboxValueFrom}&TradeCreditLimitTo=${TradeCreditLimitCheckboxValueTo}`;
    }
    return '';
  }, [TradeCreditLimitCheckboxValue, TradeCreditLimitCheckboxValueFrom, TradeCreditLimitCheckboxValueTo]);

  const createBaseQueryString = (resetPageNumber = false) => {
    return `CompanyId=${
      currentCompany ?? CompanyId
    }&MonitorClient=${MonitorClient}&PaymentCredibility=${PaymentCredibility}&PageNumber=${
      resetPageNumber ? 1 : pageNumber ?? 1
    }&GrossDebtFrom=${GrossDebtFrom}&TradeCreditLimitFilter=${handleTradeCreditLimitFilter}${tradeCreditLimitFromTo}&GrossDebtTo=${GrossDebtTo}&TradeCreditLimitChangePeriod=${ChangeLimit}&DebtValueFrom=${DebtValueFrom}&RiskGroupChangePeriod=${ChangeCredibility}&DebtValueTo=${DebtValueTo}&GrossSaleLast12MonthsFrom=${GrossSaleLast12MonthsFrom}&GrossSaleLast12MonthsTo=${GrossSaleLast12MonthsTo}&LastDebt=${LastDebt}&LastEvent=${LastEvent}&PageSize=${pageSize}&OrderByColumn=${mapOrderBy(
      orderBy
    )}&OrderByAscending=${mapSortDirection(order)}`;
  };

  useEffect(() => {
    if (filters) {
      setFilterNumber(filters?.maxDebtValue, setMaxDebtValue);
      setFilterNumber(filters?.maxGrossDebt, setMaxGrossDebt);
      setFilterNumber(filters?.maxGrossSale, setMaxGrossSale);
      setFilterNumber(filters?.minGrossDebt, setMinGrossDebt);
      setFilterNumber(filters?.minGrossSale, setMinGrossSale);
      setFilterNumber(filters?.maxTradeCreditLimitValue, setMaxTradeCreditLimit);
      setRiskGroups(filters?.riskGroups);

      const isNegativeButNumber = (value: number | string): boolean => !value && typeof value === 'number';
      const values = customerFilterValues && JSON.parse(customerFilterValues);

      formik.setValues({
        ...formik.values,
        [CUSTOMER_FIELDS.DEBT_VALUE_TO]: companyChanged
          ? filters?.maxDebtValue
          : values?.DebtValueTo || isNegativeButNumber(values?.DebtValueTo)
          ? values.DebtValueTo
          : filters?.maxDebtValue,
        [CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_FROM]: companyChanged
          ? filters?.minGrossSale
          : values?.GrossSaleLast12MonthsFrom || isNegativeButNumber(values?.GrossSaleLast12MonthsFrom)
          ? values.GrossSaleLast12MonthsFrom
          : filters?.minGrossSale,
        [CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_TO]: companyChanged
          ? filters?.maxGrossSale
          : values?.GrossSaleLast12MonthsTo || isNegativeButNumber(values?.GrossSaleLast12MonthsTo)
          ? values.GrossSaleLast12MonthsTo
          : filters?.maxGrossSale,
        [CUSTOMER_FIELDS.GROSS_DEBT_TO]: companyChanged
          ? filters?.maxGrossDebt
          : values?.GrossDebtTo || isNegativeButNumber(values?.GrossDebtTo)
          ? values.GrossDebtTo
          : filters?.maxGrossDebt,
        [CUSTOMER_FIELDS.GROSS_DEBT_FROM]: companyChanged
          ? filters?.minGrossDebt
          : values?.GrossDebtFrom || isNegativeButNumber(values?.GrossDebtFrom)
          ? values.GrossDebtFrom
          : filters?.minGrossDebt,
        [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_TO]: companyChanged
          ? filters?.maxTradeCreditLimitValue
          : values?.TradeCreditLimitCheckboxValueTo || isNegativeButNumber(values?.TradeCreditLimitCheckboxValueTo)
          ? values.TradeCreditLimitCheckboxValueTo
          : filters?.maxTradeCreditLimitValue
      });
    }
  }, [filters]);

  const [query, setQuery] = useState<string>();
  const debouncedQuery = useDebounce(query, 400);
  const debouncedIgnoreCustomFetch = useDebounce(ignoreCustomerFetch, 600);

  const parseRiskGroupCodeToId = (RiskGroupCodes: string) => {
    const riskGroupId = RiskGroupCodes?.split(',')?.map((el: string) => {
      return riskGroups?.find((riskGroup: RiskGroupItemDto) => riskGroup?.code === el)?.id;
    });
    return riskGroupId;
  };

  const prepareQuery = (resetPageNumber = false, searchFieldName?: CUSTOMER_FIELDS, newestSessionFilters?: string) => {
    let queryString = createBaseQueryString(resetPageNumber);

    if (userRole === ROLE.ADMIN_KG) {
      const currentCompany = sessionFilters ? JSON.parse(sessionFilters)?.CompanyId : firstDictCompany;
      formik.setFieldValue(CUSTOMER_FIELDS.COMPANY_ID, CompanyId ? CompanyId : currentCompany);
    }
    const company = firstDictCompany ? (CompanyId ? CompanyId : firstDictCompany) : companyId;

    const searchQuery = createSearchQuery(
      formik.values,
      searchParams,
      newestSessionFilters ?? sessionFilters,
      searchFieldName
    );

    setSearchParams(searchParams);
    const filtersQuery = createFiltersQuery(
      Name,
      Report,
      LastEvent,
      LastDebt,
      parseRiskGroupCodeToId(RiskGroupCodes)?.filter((el) => el) as number[]
    );

    if (searchQuery) {
      queryString = queryString + `&Filters=${searchQuery}`;
    }

    if (filtersQuery) {
      queryString = queryString + filtersQuery;
    }

    if (company) {
      setCurrentCompany(company);
      setQuery(queryString.replace('CompanyId=&', `CompanyId=${company}&`));
    }
    if (company && query) {
      setIgnoreCustomerFetch(false);
    }
  };

  useEffect(() => {
    if (CompanyId) {
      dispatch(getMonitoringCounterThunk(CompanyId ? CompanyId : currentCompany));
    }
  }, [CompanyId]);

  useEffect(() => {
    const company = firstDictCompany ? (CompanyId ? CompanyId : firstDictCompany) : companyId;

    if (company && query) {
      setIgnoreCustomerFetch(false);
    }
  }, [query, firstDictCompany]);

  useEffect(() => {
    if (filters || userRole === ROLE.ADMIN_KG) {
      prepareQuery();
    }
  }, [
    pageNumber,
    pageSize,
    order,
    orderBy,
    MonitorClient,
    Report,
    DebtValueFrom,
    DebtValueTo,
    PaymentCredibility,
    CompanyId,
    LastDebt,
    LastEvent,
    companiesDict,
    RiskGroupCodes,
    GrossDebtFrom,
    GrossDebtTo,
    GrossSaleLast12MonthsFrom,
    GrossSaleLast12MonthsTo,
    filters,
    riskGroups,
    firstDictCompany,
    TradeCreditLimitCheckboxValue,
    TradeCreditLimitCheckboxValueFrom,
    TradeCreditLimitCheckboxValueTo,
    ChangeCredibility,
    ChangeLimit
  ]);

  useDidUpdateEffect(() => {
    if (!filtersLoaded) {
      setFiltersLoaded(true);
      return;
    }
    if ((filters || userRole === ROLE.ADMIN_KG) && filtersLoaded) {
      goToFirstPage(PATH.CUSTOMERS_LIST);
    }
  }, [
    MonitorClient,
    Report,
    DebtValueFrom,
    DebtValueTo,
    PaymentCredibility,
    LastDebt,
    LastEvent,
    RiskGroupCodes,
    GrossDebtFrom,
    GrossDebtTo,
    GrossSaleLast12MonthsFrom,
    GrossSaleLast12MonthsTo,
    CompanyId,
    firstDictCompany,
    isEditable
  ]);

  const updateStatus = (element: CounterpartySearchItemDto) => {
    clearInterval(currentInterval.intervalId);
    const updateCustomersResult = customersResult?.map((p) =>
      p?.taxIdentificationNumber === element.taxIdentificationNumber
        ? { ...p, monitoringState: element.monitoringState }
        : p
    );
    setCustomersResult(updateCustomersResult);
  };

  useEffect(() => {
    const res = resultRequest as any;
    if (res?.pagingInfo?.filters) {
      if (res?.results.length > 0) {
        const currentCounterParties = [...counterPartiesStatus];
        const uniqueTaxNumbers = res.results.filter(
          (value: CounterpartySearchItemDto, index: number, arr: CounterpartySearchItemDto[]) =>
            arr.findIndex((value2) => value2.taxIdentificationNumber === value.taxIdentificationNumber) === index
        );

        uniqueTaxNumbers.forEach((element: CounterpartySearchItemDto) => {
          if (
            element.monitoringState === MONITORING_STATE.ERROR_ENABLING ||
            element.monitoringState === MONITORING_STATE.ERROR_DISABLING
          ) {
            updateStatus(element);
          } else {
            if (currentCounterParties.find((el) => el?.taxNumber === element.taxIdentificationNumber)) {
              currentCounterParties.map((el) => {
                if (el?.retry > 1) {
                  clearInterval(currentInterval.intervalId);
                }
                return el?.taxNumber === element.taxIdentificationNumber ? { ...el, retry: el.retry++ } : el;
              });
            } else {
              currentCounterParties.push({ taxNumber: element.taxIdentificationNumber, retry: 1 });
            }
            setCounterPartiesStatus(currentCounterParties);
          }
        });
      }
    }

    return () => {
      clearInterval(currentInterval.intervalId);
      setCurrentInterval({});
    };
  }, [resultRequest]);

  useFetch<CustomersDto>(
    useCallback(async () => {
      setIsLoadingResults(true);
      const data = await getCustomers(query);
      setCustomersData(data?.data?.Result);
      setCustomersResult(data?.data?.Result?.results);
      setIsLoadingResults(false);
      if (isInitialRequest) {
        setIsInitialRequest(false);
      } else {
        listHeaderRef?.current?.scrollIntoView();
      }
      return data;
    }, [debouncedQuery]),
    [debouncedQuery],
    debouncedIgnoreCustomFetch
  );

  const rows = useMemo(() => createRows(customersResult), [customersResult]);

  const handleRequestSort = useCallback(
    (event: MouseEvent<unknown>, property: CUSTOMER_FIELDS) => {
      const isAsc = orderBy === property && order === 'asc';
      const orderValue = isAsc ? 'desc' : 'asc';
      setOrder(orderValue);
      setOrderBy(property);
      localStorage.setItem('customerOrder', orderValue);
      localStorage.setItem('customerOrderBy', property);
    },
    [orderBy, order]
  );

  const handleSearch = (name: CUSTOMER_FIELDS) => {
    const parsedSessionFilters = JSON.parse(sessionFilters || '{}');
    const sessionFiltersWithSearch = {
      ...parsedSessionFilters,
      [name]: formik.values[name as keyof typeof formik.values]
    };
    setSessionFilters(JSON.stringify(sessionFiltersWithSearch));
    if (pageNumber === '1') {
      prepareQuery(false, name, JSON.stringify(sessionFiltersWithSearch));
    } else {
      goToFirstPage(PATH.CUSTOMERS_LIST);
    }
  };

  useEffect(() => {
    interface Item {
      [key: string]: string | number;
    }

    const parsedSearchParams: Item = {};
    for (const [key, value] of searchParams as any) {
      parsedSearchParams[key] = value;
    }

    const { page, ...restParsedSearchParams } = parsedSearchParams;

    const keys = Object.keys(restParsedSearchParams);
    const parsedSessionFilters = JSON.parse(sessionFilters || '{}');
    let valuesFromUrl = {};

    keys.forEach((key) => {
      const searchParamValue = searchParams.get(key);

      if (searchParamValue) {
        valuesFromUrl = { ...valuesFromUrl, [key]: searchParamValue };
        formik.setFieldValue(key, searchParamValue);
      }
    });

    const filters = JSON.stringify({ ...parsedSessionFilters, ...valuesFromUrl });
    if (filters !== '{}') {
      setSessionFilters(filters);
    }
  }, []);

  const handleEnterPress = (name: CUSTOMER_FIELDS) => handleSearch(name);

  const handleClearValue = (field: string) => {
    const parsedSessionFilters = JSON.parse(sessionFilters || '{}');
    const sessionFiltersWithSearch = { ...parsedSessionFilters, [field]: '' };
    setSessionFilters(JSON.stringify(sessionFiltersWithSearch));
    formik.setFieldValue(field, '');

    if (searchParams.has(field)) {
      searchParams.delete(field);
      setSearchParams(searchParams);
      setHandleClearValueCounter(handleClearValueCounter + 1);
      goToFirstPage(PATH.CUSTOMERS_LIST);
    }
  };

  useEffect(() => {
    if (handleClearValueCounter) {
      prepareQuery(true);
    }
  }, [handleClearValueCounter]);

  const handleChangeRowsPerPage = useCallback((event: SelectChangeEvent<number>) => {
    setPageSize(Number(event.target.value));
    goToFirstPage(PATH.CUSTOMERS_LIST);
  }, []);

  const updateMonitoringStates = (taxNumbers: Set<string>, updateSelection: boolean) => {
    const newMonitoringState = MONITORING_STATE.CREATED;

    formik.setFieldValue(CUSTOMER_FIELDS.ACTION, '');

    const updateCustomersResult = customersResult?.map((p) =>
      p?.taxIdentificationNumber && Array.from(taxNumbers).includes(p?.taxIdentificationNumber)
        ? { ...p, monitoringState: newMonitoringState }
        : p
    );
    setCustomersResult(updateCustomersResult);

    if (updateSelection) {
      const updateSelectedMonitoringStates = new Map(selectedMonitoringStates);

      taxNumbers.forEach((taxId) => {
        if (selectedMonitoringStates.has(taxId)) {
          updateSelectedMonitoringStates.set(taxId, newMonitoringState);
        }
      });
      setSelectedMonitoringStates(updateSelectedMonitoringStates);
    }

    prepareQuery();
  };

  const startMonitoringHandler = useCallback(
    (earliestDate) => enableMonitoring(companyId, Array.from(selectedCustomers), earliestDate),
    [selectedCustomers]
  );
  const starMonitoring = (earliestDate: string) =>
    sendData(
      () => startMonitoringHandler(earliestDate),
      () => updateMonitoringStates(selectedCustomers, false)
    );

  const startSingleMonitoringHandler = useCallback(
    (d, earliestDate) => enableMonitoring(companyId, d, earliestDate),
    [selectedCustomers]
  );
  const starSingleMonitoring = (taxId: string, earliestDate: string) =>
    sendData(
      () => startSingleMonitoringHandler([taxId], earliestDate),
      () => updateMonitoringStates(new Set([taxId]), true)
    );

  const stopMonitoringHandler = useCallback(
    (earliestDate?: string) =>
      disableMonitoring(companyId, Array.from(selectedMonitoringIds), earliestDate ? earliestDate : date),
    [selectedMonitoringIds, date]
  );

  const stopMonitoring = (earliestDate?: string) =>
    sendData(
      () => stopMonitoringHandler(earliestDate),
      () => cancelMonitoring(selectedMonitoringIds)
    );

  const queryString = createBaseQueryString();

  const checkMonitoringHandler = (data: any) => {
    if (typeof data === 'string') {
      return getCustomers(`${queryString}&Filters=${FIELDS.TAX_NUMBER};contains;${data}`);
    } else {
      //@todo zweryfikowac z Michalem
      // const arr = Array.from(data).map((el) => `${FIELDS.TAX_NUMBER};contains;${el}`);
      // const res = arr.join('|');
      // return getCustomers(`${queryString}&Filters=${res}`);
      return getCustomers(`${query}`);
    }
  };

  const checkMonitoringStatus = (data: any) => sendData(() => checkMonitoringHandler(data));

  const enableSingleMonitoring = (taxIdentificationNumber: string, earliestDate: string): void => {
    starSingleMonitoring(taxIdentificationNumber, earliestDate);
    const checkMonitoringInterval = setInterval(() => checkMonitoringStatus(taxIdentificationNumber), 1800000); //@todo zmienić na 30 minut
    setCurrentInterval({ intervalId: checkMonitoringInterval });
  };

  const handleEnableSingleMonitoring = async (taxIdentificationNumber: string) => {
    setActionType(ACTION_TYPES.SINGLE);
    setSelectedTaxIdentificationNumber(taxIdentificationNumber);
    const { canEnableMonitoring, earliestDate } = await canEnable();
    setEarliestDate(earliestDate);

    if (canEnableMonitoring) {
      enableSingleMonitoring(taxIdentificationNumber, earliestDate);
    } else {
      openModal();
    }
  };

  useEffect(() => {
    if (monitoringTaxId) {
      setMonitoringTaxId('');
      handleEnableSingleMonitoring(monitoringTaxId);
    }
  }, [monitoringTaxId]);

  const cancelMonitoring = (monitoringIds: Set<string>) => {
    const ids = Array.from(monitoringIds).map((el) => `${el}`);
    if (moment(date).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY')) {
      const updateCustomersResult = customersResult?.map((p) =>
        p?.taxIdentificationNumber && ids.includes(`${p.monitoringId}`)
          ? { ...p, monitoringState: MONITORING_STATE.DISABLING }
          : p
      );
      setCustomersResult(updateCustomersResult);
    }
    prepareQuery();
  };

  const handleCheckMonitoringStatus = () => {
    const checkMonitoringInterval = setInterval(() => checkMonitoringStatus(selectedCustomers), 15000); //@todo zmienić na 30 minut
    setCurrentInterval({ intervalId: checkMonitoringInterval });
  };

  const enableMonitoringForSelected = (earliestDate: string) => {
    starMonitoring(earliestDate);
    formik.setFieldValue(CUSTOMER_FIELDS.ACTION, '');
    setSelectedMonitoringIds(new Set());
    setSelectedCustomers(new Set());
    setSelectedMonitoringStates(new Map());
    setIsCheck([]);
    setIsCheckAll(false);
  };

  const disableMonitoringForSelected = () => {
    setIsSelectedDisable(true);
  };

  const handleActions = async (type: string) => {
    setActionType(ACTION_TYPES.MULTIPLE);
    if (type === ACTIONS.START_MONITORING) {
      setAction(ACTIONS.START_MONITORING);
      const { canEnableMonitoring, earliestDate } = await canEnable();
      setEarliestDate(earliestDate);

      if (canEnableMonitoring) {
        enableMonitoringForSelected(earliestDate);
        handleCheckMonitoringStatus();
        setSelectAllCheckboxChecked(false);
      } else {
        openModal();
      }
    } else if (type === ACTIONS.CANCEL_MONITORING) {
      setAction(ACTIONS.CANCEL_MONITORING);
      disableMonitoringForSelected();
      handleCheckMonitoringStatus();
    }
  };

  const handleCustomerCheckbox = useCallback(
    (
      taxNumber: string,
      monitoringId: string | undefined,
      monitoringState: MONITORING_STATE | undefined,
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const currentSelectedCustomers = new Set(selectedCustomers);
      const currentSelectedMonitoringIds = new Set(selectedMonitoringIds);
      const currentSelectedMonitoringStates = new Map(selectedMonitoringStates);
      if (event.target.checked) {
        currentSelectedMonitoringStates.set(taxNumber, monitoringState);
        setSelectedMonitoringStates(currentSelectedMonitoringStates);

        if (monitoringId) {
          currentSelectedMonitoringIds.add(monitoringId);
          setSelectedMonitoringIds(currentSelectedMonitoringIds);
        } else {
          currentSelectedCustomers.add(taxNumber);
          setSelectedCustomers(currentSelectedCustomers);
        }
      } else {
        currentSelectedMonitoringStates.delete(taxNumber);
        setSelectedMonitoringStates(currentSelectedMonitoringStates);

        if (monitoringId) {
          currentSelectedMonitoringIds.delete(monitoringId);
          setSelectedMonitoringIds(currentSelectedMonitoringIds);
        } else {
          currentSelectedCustomers.delete(taxNumber);
          setSelectedCustomers(currentSelectedCustomers);
        }
      }
    },
    [selectedCustomers, selectedMonitoringIds, selectedMonitoringStates]
  );

  const handleSelectAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectAllCheckboxChecked(!selectAllCheckboxChecked);
      setIsCheckAll(!isCheckAll);
      setIsCheck(rows.map((item) => item.id + ''));
      const selectCustomers: Set<string> = new Set();
      const selectMonitoringIds: Set<string> = new Set();
      const selectMonitoringStates: Map<string, MONITORING_STATE | undefined> = new Map();

      rows.forEach((item) => {
        selectMonitoringStates.set(item.taxIdentificationNumber, item.monitoringState);
        if (item.monitoringId) {
          selectMonitoringIds.add(item.monitoringId);
        } else {
          selectCustomers.add(item.taxIdentificationNumber);
        }
      });

      if (isCheckAll) {
        setSelectedCustomers(new Set());
        setSelectedMonitoringIds(new Set());
        setSelectedMonitoringStates(new Map());
        setIsCheck([]);
      } else {
        setSelectedMonitoringIds(new Set(selectMonitoringIds));
        setSelectedCustomers(new Set(selectCustomers));
        setSelectedMonitoringStates(new Map(selectMonitoringStates));
      }
    },
    [selectAllCheckboxChecked, isCheckAll, rows]
  );

  const handleCheckboxClick = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { id, checked } = e.target;
      setIsCheck([...isCheck, id]);
      if (!checked) {
        setIsCheck(isCheck.filter((item) => item !== id));
      }
    },
    [isCheck]
  );

  const handleDateChange = (newValue: Date | null) => {
    setDate(newValue);
  };

  const checkboxHandler =
    (setFieldValue: CUSTOMER_FIELDS, firstField: CUSTOMER_FIELDS, secondField: CUSTOMER_FIELDS, secondValue: number) =>
    (event: any) => {
      const { value, checked } = event.target;
      formik.setFieldValue(firstField, checked);

      if (checked && formik.values[secondField]) {
        formik.setFieldValue(setFieldValue, 2);
      } else if (!checked && formik.values[secondField]) {
        formik.setFieldValue(setFieldValue, secondValue);
      } else if (!checked && !formik.values[secondField]) {
        formik.setFieldValue(setFieldValue, 2);
      } else {
        formik.setFieldValue(setFieldValue, value);
      }
    };

  const radioBoxHandler = useCallback((event: any, field: CUSTOMER_FIELDS) => {
    const { value, checked } = event.target;

    if (checked) {
      formik.setFieldValue(field, value);
    }
  }, []);

  const multiCheckboxHandler = useCallback(
    (event: any, field: CUSTOMER_FIELDS.RISK_GROUP_CODES) => {
      const { value, checked } = event.target;
      const currentSelection = formik.values[field] ? formik.values[field].split(',') : [];

      if (checked && value) {
        currentSelection.push(value);
        formik.setFieldValue(field, currentSelection.join());
      } else {
        const newSelection = currentSelection.filter((field) => field !== value);
        formik.setFieldValue(field, newSelection.join());
      }
    },
    [formik.values[CUSTOMER_FIELDS.RISK_GROUP_CODES]]
  );

  const clearFilters = (onlyWithDebt = false) => {
    const values = {
      ...formik.values,
      [CUSTOMER_FIELDS.DEBT_VALUE_FROM]: onlyWithDebt ? 0.01 : 0,
      [CUSTOMER_FIELDS.DEBT_VALUE_TO]: maxDebtValue,
      [CUSTOMER_FIELDS.GROSS_DEBT_FROM]: minGrossDebt,
      [CUSTOMER_FIELDS.GROSS_DEBT_TO]: maxGrossDebt,
      [CUSTOMER_FIELDS.MONITORING_STATE]: '',
      [CUSTOMER_FIELDS.REPORT]: false,
      [CUSTOMER_FIELDS.PAYMENT_CREDIBILITY]: 2,
      [CUSTOMER_FIELDS.PAYMENT_CREDIBILITY_DEBTOR]: false,
      [CUSTOMER_FIELDS.PAYMENT_CREDIBILITY_NON_DEBTOR]: false,
      [CUSTOMER_FIELDS.MONITOR_CLIENT]: 2,
      [CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_FROM]: minGrossSale,
      [CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_TO]: maxGrossSale,
      [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_TO]: maxTradeCreditLimit,
      [CUSTOMER_FIELDS.MONITOR_CLIENT_ENABLED]: false,
      [CUSTOMER_FIELDS.MONITOR_CLIENT_DISABLED]: false,
      [CUSTOMER_FIELDS.SCORING]: '',
      [CUSTOMER_FIELDS.RISK_GROUP_CODES]: '',
      [CUSTOMER_FIELDS.LAST_DEBT]: RADIOGROUP_VALUES.ALL,
      [CUSTOMER_FIELDS.LAST_EVENT]: RADIOGROUP_VALUES.ALL,
      [CUSTOMER_FIELDS.CHANGE_CREDIBILITY]: RADIOGROUP_VALUES.ALL,
      [CUSTOMER_FIELDS.CHANGE_LIMIT]: RADIOGROUP_VALUES.ALL,
      [CUSTOMER_FIELDS.COMPANY_ID]: firstDictCompany,
      [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_FROM]: 0,
      [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE]: []
    };

    formik.setValues(values);
    setSessionFilters(JSON.stringify(values));

    searchParams.set('page', '1');
    navigate({ pathname: PATH.CUSTOMERS_LIST, search: `${searchParams}` });
  };

  const requestCsvExport = (data: CustomerFormikValues) => {
    if (userRole === ROLE.ADMIN_KG) {
      const currentCompany = sessionFilters ? JSON.parse(sessionFilters)?.CompanyId : firstDictCompany;
      formik.setFieldValue(CUSTOMER_FIELDS.COMPANY_ID, CompanyId ? CompanyId : currentCompany);
    }

    const company = firstDictCompany ? CompanyId : companyId;

    const newData: ExportCounterpartiesDto = {
      monitorClient: Number(data[CUSTOMER_FIELDS.MONITOR_CLIENT]),
      debtValueFrom: data[CUSTOMER_FIELDS.DEBT_VALUE_FROM],
      debtValueTo: data[CUSTOMER_FIELDS.DEBT_VALUE_TO],
      report: data[CUSTOMER_FIELDS.REPORT],
      companyId: Number(company),
      riskGroupIds: parseRiskGroupCodeToId(data[CUSTOMER_FIELDS.RISK_GROUP_CODES]).filter((el) => el) as number[],
      grossSaleLast12MonthsFrom: data[CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_FROM],
      grossSaleLast12MonthsTo: data[CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_TO],
      grossDebtFrom: data[CUSTOMER_FIELDS.GROSS_DEBT_FROM],
      grossDebtTo: data[CUSTOMER_FIELDS.GROSS_DEBT_TO],
      lastDebt: Number(data[CUSTOMER_FIELDS.LAST_DEBT]),
      lastEvent: Number(data[CUSTOMER_FIELDS.LAST_EVENT]),
      orderByColumn: getOrderByColumnString(orderBy),
      orderByAscending: order === 'asc' ? true : false,
      tradeCreditLimitChangePeriod: Number(data[CUSTOMER_FIELDS.CHANGE_LIMIT]),
      riskGroupChangePeriod: Number(data[CUSTOMER_FIELDS.CHANGE_CREDIBILITY]),
      tradeCreditLimitFilter: handleTradeCreditLimitFilter,
      tradeCreditLimitFrom: Number(data[CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_FROM]),
      tradeCreditLimitTo: Number(data[CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_TO]),
      filters: createExportToCsvSearchFilters(searchParams)
    };

    sendExportRequest(
      () => exportToCsv(newData),
      () => setIsExportModalVisible(true)
    );
  };

  const getOrderByColumnString = (orderByValue: string): string => {
    switch (orderByValue) {
      case CUSTOMER_FIELDS.SCORING:
        return CUSTOMER_FIELDS.DEBT_AMOUNT_PLN;
      case CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT:
        return CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_VALUE;
      default:
        return orderByValue;
    }
  };

  const handleRangeSlider = useCallback((values: any[], firstField: CUSTOMER_FIELDS, secondField: CUSTOMER_FIELDS) => {
    formik.setFieldValue(firstField, values[0]);
    formik.setFieldValue(secondField, values[1]);
  }, []);

  const handleDragStop = useCallback((values: any[], firstField: CUSTOMER_FIELDS, secondField: CUSTOMER_FIELDS) => {
    formik.setFieldValue(firstField, values[0]);
    formik.setFieldValue(secondField, values[1]);
  }, []);

  const cancelActions = () => {
    formik.setFieldValue(CUSTOMER_FIELDS.ACTION, '');
    setIsSelectedDisable(false);
  };

  const closeExportModal = () => {
    setIsExportModalVisible(false);
  };

  const acceptActions = (earliestDate?: string) => {
    formik.setFieldValue(CUSTOMER_FIELDS.ACTION, '');
    setSelectedMonitoringIds(new Set());
    setSelectedCustomers(new Set());
    setSelectedMonitoringStates(new Map());
    setIsCheck([]);
    setIsCheckAll(false);
    setIsSelectedDisable(false);
    stopMonitoring(earliestDate);
  };

  const handleAcceptActions = async () => {
    const dateIsToday = moment(date).isSame(moment(), 'day');

    if (dateIsToday) {
      const { canDisableMonitoring, earliestDate } = await canDisable();
      if (canDisableMonitoring) {
        acceptActions();
      } else {
        setEarliestDate(earliestDate);
        setIsSelectedDisable(false);
        openModal();
      }
    } else {
      acceptActions();
    }
    setSelectAllCheckboxChecked(false);
  };

  const fields: CustomerFieldType[] = [
    {
      name: CUSTOMER_FIELDS.NAME,
      value: formik.values[CUSTOMER_FIELDS.NAME],
      error: formik.touched[CUSTOMER_FIELDS.NAME] && !!formik.errors[CUSTOMER_FIELDS.NAME],
      helperText: formik.touched[CUSTOMER_FIELDS.NAME] && formik.errors[CUSTOMER_FIELDS.NAME],
      width: 125
    },
    {
      name: CUSTOMER_FIELDS.TAX_NUMBER,
      value: formik.values[CUSTOMER_FIELDS.TAX_NUMBER],
      error: formik.touched[CUSTOMER_FIELDS.TAX_NUMBER] && !!formik.errors[CUSTOMER_FIELDS.TAX_NUMBER],
      helperText: formik.touched[CUSTOMER_FIELDS.TAX_NUMBER] && formik.errors[CUSTOMER_FIELDS.TAX_NUMBER],
      width: 162
    },
    {
      name: 'separator',
      value: '',
      separatorValue: 4
    },
    {
      name: CUSTOMER_FIELDS.DESCRIPTION1,
      value: formik.values[CUSTOMER_FIELDS.DESCRIPTION1],
      error: formik.touched[CUSTOMER_FIELDS.DESCRIPTION1] && !!formik.errors[CUSTOMER_FIELDS.DESCRIPTION1],
      helperText: formik.touched[CUSTOMER_FIELDS.DESCRIPTION1] && formik.errors[CUSTOMER_FIELDS.DESCRIPTION1],
      width: 125
    },
    {
      name: CUSTOMER_FIELDS.DESCRIPTION2,
      value: formik.values[CUSTOMER_FIELDS.DESCRIPTION2],
      error: formik.touched[CUSTOMER_FIELDS.DESCRIPTION2] && !!formik.errors[CUSTOMER_FIELDS.DESCRIPTION2],
      helperText: formik.touched[CUSTOMER_FIELDS.DESCRIPTION2] && formik.errors[CUSTOMER_FIELDS.DESCRIPTION2],
      width: 125
    },
    {
      name: 'separator',
      value: '',
      separatorValue: 3
    }
  ];

  const headCells: HeadCell[] = useMemo(
    () => [
      {
        id: CUSTOMER_FIELDS.ACTION,
        align: 'left',
        disablePadding: true,
        label: '',
        sortable: false,
        checkbox: true,
        userRole,
        callback: (e) => handleSelectAll(e),
        checked: selectAllCheckboxChecked
      },
      {
        id: CUSTOMER_FIELDS.NAME,
        align: 'left',
        disablePadding: true,
        label: 'Klient',
        sortable: true
      },
      {
        id: CUSTOMER_FIELDS.TAX_NUMBER,
        align: 'left',
        disablePadding: true,
        label: 'NIP',
        sortable: false,
        width: '200px'
      },
      {
        id: CUSTOMER_FIELDS.STATE,
        align: 'left',
        disablePadding: true,
        label: 'Wiarygodność',
        sortable: false,
        textAlign: 'center'
      },
      {
        id: CUSTOMER_FIELDS.SCORING,
        align: 'left',
        disablePadding: true,
        label: 'Zadłużenie w KRD',
        sortable: true,
        showCurrency: true
      },
      {
        id: CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT,
        align: 'left',
        disablePadding: true,
        label: 'Limit kupiecki',
        sortable: true,
        showCurrency: true
      },
      {
        id: CUSTOMER_FIELDS.REPORT,
        align: 'left',
        disablePadding: true,
        label: 'Raporty',
        sortable: false,
        userRole,
        roles: [ROLE.CLIENT_USER, ROLE.CLIENT_ADMIN, ROLE.CURATOR]
      },
      {
        id: CUSTOMER_FIELDS.DESCRIPTION1,
        align: 'left',
        disablePadding: true,
        label: 'Opis 1',
        sortable: true
      },
      {
        id: CUSTOMER_FIELDS.DESCRIPTION2,
        align: 'left',
        disablePadding: true,
        label: 'Opis 2',
        sortable: true
      },
      {
        id: CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS,
        align: 'right',
        disablePadding: true,
        label: 'Sprzedaż',
        sortable: true,
        showCurrency: true
      },
      {
        id: CUSTOMER_FIELDS.GROSS_DEBT,
        align: 'right',
        disablePadding: true,
        label: 'Należności',
        sortable: true,
        showCurrency: true
      },
      {
        id: null,
        align: 'right',
        disablePadding: true,
        label: '',
        sortable: false,
        width: '100%'
      }
    ],
    [selectAllCheckboxChecked, rows, isCheckAll]
  );

  const isDisableMonitoringButtonDisabled = useMemo(() => {
    return Array.from(selectedMonitoringStates.values()).some((item) => item !== MONITORING_STATE.ENABLED);
  }, [selectedMonitoringStates]);

  const isCounterpartyImportInProgress = useMemo(() => {
    if (isCheckAll) {
      return !customersData?.results.every((item) => item.isEditable === true);
    }

    const selectedCounterparties = Array.from(isCheck.map((id) => Number(id)));
    const filteredResults =
      customersData?.results.filter((item) => selectedCounterparties.includes(item.id)).map((x) => x.isEditable) || [];
    return !filteredResults.every((item) => item === true);
  }, [isCheck, isCheckAll]);

  const selectedItemsPanel = () => {
    if (isCheck.length) {
      return (
        <Box
          sx={{
            typography: 'body2',
            display: 'flex',
            flexShrink: '0',
            alignItems: 'center',
            padding: '1rem 1.25rem 1rem 1rem',
            background: 'rgba(7,138, 81, 0.1)',
            height: '69.5px',
            justifyContent: 'space-between',
            alignItem: 'center'
          }}>
          <Box sx={{ fontSize: '20px', fontWeight: '600', color: '#078A51' }}>
            Wybrano {isCheck.length} {isCheck.length > 1 ? 'klientów' : 'klienta'}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {hasMonitoringPermission === 'True' && (
              <>
                <Box sx={{ fontSize: '16px' }}>Wykonaj akcję grupową dla wybranych klientów</Box>
                <CustomTooltip text={tooltipSelectedItemsText} link={tooltipSelectedItemsLink} />
              </>
            )}
            {hasMonitoringPermission === 'True' ? (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ButtonWithTooltip
                    tooltipOptions={{
                      active: isCounterpartyImportInProgress,
                      text: tooltipImportInProgress,
                      noIcon: true
                    }}
                    onClick={() => handleActions(ACTIONS.START_MONITORING)}
                    variant="contained"
                    sx={{ display: 'flex', flexWrap: 'nowrap', flexShrink: '0', margin: '0 1rem' }}
                    disabled={isCounterpartyImportInProgress}>
                    Włącz monitorowanie
                  </ButtonWithTooltip>
                  <ButtonWithTooltip
                    tooltipOptions={{
                      active: isCounterpartyImportInProgress,
                      text: tooltipImportInProgress,
                      noIcon: true
                    }}
                    onClick={() => handleActions(ACTIONS.CANCEL_MONITORING)}
                    variant="outlined"
                    disabled={isDisableMonitoringButtonDisabled || isCounterpartyImportInProgress}
                    sx={{ display: 'flex', flexWrap: 'nowrap', flexShrink: '0' }}>
                    Wyłącz monitorowanie
                  </ButtonWithTooltip>
                </Box>
              </>
            ) : (
              'Nie posiadasz uprawnienia do włączania i wyłączania monitorowania klientów.'
            )}
          </Box>
        </Box>
      );
    }

    return null;
  };

  const acceptCheckMonitoring = () => {
    if (actionType === ACTION_TYPES.SINGLE) {
      enableSingleMonitoring(selectedTaxIdentificationNumber, earliestDate);
      cancelAction();
    } else {
      if (action === ACTIONS.START_MONITORING) {
        enableMonitoringForSelected(earliestDate);
        handleCheckMonitoringStatus();
      } else {
        acceptActions(earliestDate);
      }
      cancelAction();
    }
  };

  const debtValues = useMemo(
    () => [formik.values[CUSTOMER_FIELDS.DEBT_VALUE_FROM], formik.values[CUSTOMER_FIELDS.DEBT_VALUE_TO]],
    [formik.values[CUSTOMER_FIELDS.DEBT_VALUE_FROM], formik.values[CUSTOMER_FIELDS.DEBT_VALUE_TO]]
  );

  const grossDebtValue = useMemo(
    () => [formik.values[CUSTOMER_FIELDS.GROSS_DEBT_FROM], formik.values[CUSTOMER_FIELDS.GROSS_DEBT_TO]],
    [formik.values[CUSTOMER_FIELDS.GROSS_DEBT_FROM], formik.values[CUSTOMER_FIELDS.GROSS_DEBT_TO]]
  );

  const tradeCreditValue = useMemo(
    () => [
      formik.values[CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_FROM],
      formik.values[CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_TO]
    ],
    [
      formik.values[CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_FROM],
      formik.values[CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_TO]
    ]
  );

  const grossSaleValues = useMemo(
    () => [
      formik.values[CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_FROM],
      formik.values[CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_TO]
    ],
    [
      formik.values[CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_FROM],
      formik.values[CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_TO]
    ]
  );

  const lastEventValue = useMemo(
    () => Number(formik.values[CUSTOMER_FIELDS.LAST_EVENT]),
    [formik.values[CUSTOMER_FIELDS.LAST_EVENT]]
  );

  const lastDebtValue = useMemo(
    () => Number(formik.values[CUSTOMER_FIELDS.LAST_DEBT]),
    [formik.values[CUSTOMER_FIELDS.LAST_DEBT]]
  );

  const changeCredibilityValue = useMemo(
    () => Number(formik.values[CUSTOMER_FIELDS.CHANGE_CREDIBILITY]),
    [formik.values[CUSTOMER_FIELDS.CHANGE_CREDIBILITY]]
  );

  const changeLimitValue = useMemo(
    () => Number(formik.values[CUSTOMER_FIELDS.CHANGE_LIMIT]),
    [formik.values[CUSTOMER_FIELDS.CHANGE_LIMIT]]
  );

  const riskGroupValue = useMemo(
    () => formik.values[CUSTOMER_FIELDS.RISK_GROUP_CODES],
    [formik.values[CUSTOMER_FIELDS.RISK_GROUP_CODES]]
  );

  const selectTradeCreditLimit = () => {
    if (
      !(
        formik.values[CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE] as TRADE_CREDIT_LIMIT_FILTER_VALUES[]
      )?.includes(TRADE_CREDIT_LIMIT_FILTER_VALUES.VALUE)
    ) {
      formik.setFieldValue(CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE, [
        ...formik.values[CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE],
        TRADE_CREDIT_LIMIT_FILTER_VALUES.VALUE
      ]);
    }
  };

  return !localStorage.getItem('authToken') ? null : (
    <form onSubmit={(e) => e.preventDefault()}>
      <Helmet>
        <title>RiskRadar - Klienci</title>
      </Helmet>
      <CheckMonitorDialog isOpen={isOpen} cancelAction={cancelAction} accept={acceptCheckMonitoring} />
      <Box sx={{ display: 'flex' }}>
        <Box className={classes.sidebar}>
          <SideNav />
          <Paper margin="0 0 0.75rem 0.75rem">
            <FiltersTop
              disabled={isDefaultValues}
              clearFilters={() => clearFilters(false)}
              text={tooltipFilterstText}
              link={tooltipFilterstLink}
            />

            {[ROLE.ADMIN_KG, ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER, ROLE.CURATOR].includes(userRole) && (
              <Box sx={{ display: 'block', width: '100%' }}>
                <Box sx={{ display: 'block', width: '100%' }}>
                  <CheckboxGroup
                    title="Monitorowanie klienta"
                    tooltipText={TOOLTIPS.MONITORING}
                    tooltipLink={tooltipMonitoringClientsLink}
                    flexDirection={'row'}>
                    {monitorClientCheckboxes.map((checkbox) => (
                      <FormControlLabel
                        onChange={checkboxHandler(
                          checkbox.setFieldValue,
                          checkbox.firstField,
                          checkbox.secondField,
                          checkbox.secondValue
                        )}
                        value={checkbox.value}
                        name={checkbox.firstField}
                        key={checkbox.label}
                        control={
                          <StyledCheckbox
                            sx={{ padding: '0.2rem 0.7rem 0.2rem 0.5rem' }}
                            checked={!!formik.values[checkbox.firstField]}
                          />
                        }
                        label={checkbox.label}
                      />
                    ))}
                  </CheckboxGroup>
                </Box>
                <MultiCheckbox
                  multiCheckboxHandler={multiCheckboxHandler}
                  title="Wiarygodność płatnicza"
                  tooltipText={TOOLTIPS.CREDIBILITY}
                  tooltipLink={tooltipCredibilityLink}
                  name={CUSTOMER_FIELDS.RISK_GROUP_CODES}
                  value={riskGroupValue}
                  checkboxesData={paymentCredibility}
                />
                <RangeSlider
                  title="Zadłużenie w KRD"
                  tooltipText={TOOLTIPS.DEBT}
                  tooltipLink={tooltipDebtLink}
                  name={CUSTOMER_FIELDS.DEBT_VALUE_FROM}
                  from={CUSTOMER_FIELDS.DEBT_VALUE_FROM}
                  to={CUSTOMER_FIELDS.DEBT_VALUE_TO}
                  value={debtValues}
                  handleChangeCommitted={handleRangeSlider}
                  maxValue={maxDebtValue}
                />
                {[ROLE.ADMIN_KG].includes(userRole) && (
                  <Box sx={{ display: 'block', width: '100%' }}>
                    <Filter
                      title="Firma"
                      tooltipText={TOOLTIPS.COMPANY}
                      name={CUSTOMER_FIELDS.COMPANY_ID}
                      value={
                        companiesDict?.dictionary && companiesDict?.dictionary.length > 0
                          ? CompanyId
                            ? CompanyId
                            : companiesDict?.dictionary[0].id
                          : ''
                      }
                      handleChange={(event: SelectChangeEvent) => {
                        setCompanyChanged(true);
                        formik.handleChange(event);
                      }}
                      options={companiesDict?.dictionary.sort((a, b) => a.value.localeCompare(b.value)) || []}
                    />
                  </Box>
                )}
                <CheckboxGroup
                  title="Limit kupiecki"
                  flexDirection={'column'}
                  tooltipText={TOOLTIPS.TRADE_CREDIT_LIMIT}
                  tooltipLink={tooltipTradeCreditLimitLink}>
                  {tradeCreditLimitFilters.map((checkbox) => (
                    <FormControlLabel
                      onChange={(event: React.SyntheticEvent<Element, Event>) => {
                        const checked = (event.target as HTMLInputElement).checked;
                        const value = (event.target as HTMLInputElement).value;
                        if (checked) {
                          formik.setFieldValue(CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE, [
                            ...formik.values[CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE],
                            value
                          ]);
                        } else {
                          if (value === TRADE_CREDIT_LIMIT_OPTIONS.Calculated) {
                            formik.setValues({
                              ...formik.values,
                              [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_TO]: maxTradeCreditLimit,
                              [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_FROM]: 0
                            });
                          }
                          formik.setFieldValue(
                            CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE,
                            formik.values[CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE].filter(
                              (el) => el !== value
                            )
                          );
                        }
                      }}
                      value={checkbox.value}
                      name={checkbox.label}
                      key={checkbox.label}
                      control={
                        <StyledCheckbox
                          disabled={!filters?.isTradeCreditLimitEnabled}
                          sx={{ padding: '0.2rem 0.7rem 0.2rem 0.5rem' }}
                          checked={(
                            formik.values[
                              CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE
                            ] as TRADE_CREDIT_LIMIT_FILTER_VALUES[]
                          )?.includes(checkbox.value)}
                        />
                      }
                      label={checkbox.label}
                    />
                  ))}
                  <RangeSlider
                    title=""
                    tooltipText={''}
                    name={CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_FROM}
                    from={CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_FROM}
                    to={CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_TO}
                    value={tradeCreditValue}
                    handleChangeCommitted={handleRangeSlider}
                    maxValue={maxTradeCreditLimit}
                    isInsideAnotherElement
                    onChangeCallback={selectTradeCreditLimit}
                    isDisabled={!filters?.isTradeCreditLimitEnabled}
                  />
                </CheckboxGroup>
                <Radio
                  radioBoxHandler={radioBoxHandler}
                  tooltipText={TOOLTIPS.LAST_DEBT}
                  tooltipLink={tooltipLastDebtLink}
                  name={CUSTOMER_FIELDS.LAST_DEBT}
                  radioButtonsData={lastAddedDebt}
                  value={lastDebtValue}
                  title="Ostatni dług dodany do KRD"
                />
                <Radio
                  radioBoxHandler={radioBoxHandler}
                  tooltipText={TOOLTIPS.KRD}
                  tooltipLink={tooltipLastDebtLink}
                  name={CUSTOMER_FIELDS.LAST_EVENT}
                  radioButtonsData={lastEvent}
                  value={lastEventValue}
                  title="Zdarzenia w KRD"
                />
                <Radio
                  radioBoxHandler={radioBoxHandler}
                  tooltipText={TOOLTIPS.CHANGE_CREDITBILITY}
                  tooltipLink={tooltipChangeCredibilityLink}
                  name={CUSTOMER_FIELDS.CHANGE_CREDIBILITY}
                  radioButtonsData={changeCredibilityRadio}
                  value={changeCredibilityValue}
                  title="Zmiana wiarygodności"
                />
                <Radio
                  radioBoxHandler={radioBoxHandler}
                  tooltipText={TOOLTIPS.CHANGE_LIMIT}
                  tooltipLink={tooltipChangeLimitLink}
                  name={CUSTOMER_FIELDS.CHANGE_LIMIT}
                  radioButtonsData={changeLimitRadio}
                  value={changeLimitValue}
                  title="Zmiana limitu kupieckiego"
                  isDisabled={!filters?.isTradeCreditLimitEnabled}
                />
                <FilterSwitch
                  title="Pobrany raport o kliencie"
                  tooltipText={TOOLTIPS.REPORTS}
                  tooltipLink={tooltipReportsLink}
                  name={CUSTOMER_FIELDS.REPORT}
                  isChecked={!!formik.values[CUSTOMER_FIELDS.REPORT]}
                  setFieldValue={formik.setFieldValue}
                  error={formik.touched[CUSTOMER_FIELDS.REPORT] && !!formik.errors[CUSTOMER_FIELDS.REPORT]}
                  label="Pobrany raport o kliencie"
                />
                <RangeSlider
                  title="Sprzedaż brutto"
                  tooltipText={TOOLTIPS.SALE_GROSS}
                  tooltipLink={tooltipSaleLink}
                  name={CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_FROM}
                  from={CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_FROM}
                  to={CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_TO}
                  value={grossSaleValues}
                  handleChangeCommitted={handleDragStop}
                  maxValue={maxGrossSale}
                />
                <RangeSlider
                  title="Należności brutto"
                  tooltipText={TOOLTIPS.DEBT_GROSS}
                  tooltipLink={tooltipSaleLink}
                  name={CUSTOMER_FIELDS.GROSS_DEBT_FROM}
                  from={CUSTOMER_FIELDS.GROSS_DEBT_FROM}
                  to={CUSTOMER_FIELDS.GROSS_DEBT_TO}
                  value={grossDebtValue}
                  handleChangeCommitted={handleRangeSlider}
                  maxValue={maxGrossDebt}
                  isLast
                />
              </Box>
            )}
          </Paper>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', flexDirection: 'column' }}>
          {[ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER, ROLE.ADMIN_KG, ROLE.CURATOR].includes(userRole) && (
            <Paper overflow fullSize height="650px">
              <Breadcrumbs breadcrumbs={breadcrumbs} />
              {currentCompany && filters && (
                <Charts
                  setCategory={setCategory}
                  clearFilters={clearFilters}
                  company={currentCompany}
                  showFilterButton={maxDebtValue !== 0}
                />
              )}
            </Paper>
          )}
          <Paper overflow fullSize flexGrow>
            {[ROLE.ADMIN_KG].includes(userRole) && <Breadcrumbs breadcrumbs={breadcrumbs} />}
            {isLoadingResults ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15rem 0' }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {isCheck.length > 0 ? (
                  selectedItemsPanel()
                ) : (
                  <div ref={listHeaderRef}>
                    <Header
                      title="Lista klientów"
                      tooltipText={TOOLTIPS.CLIENTSLIST}
                      tooltipLink={tooltipClientsListLink}
                      paginationTotalSize={customersData?.totalSize}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      paginationPageSize={pageSize}>
                      {[ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER, ROLE.CURATOR].includes(userRole) && (
                        <>
                          <StyledButton
                            variant="contained"
                            sx={{ display: 'flex', flexWrap: 'nowrap', flexShrink: '0', margin: '0 0 0 .5rem' }}
                            data-test="addClient"
                            //@ts-ignore //@todo
                            component={Link}
                            to={PATH.ADD_CUSTOMER}
                            target="_blank"
                            rel="noopener noreferrer"
                            startIcon={<AddCircleOutlineIcon />}>
                            Dodaj klienta
                          </StyledButton>
                          <StyledButton
                            variant="outlined"
                            sx={{
                              border: '1px solid rgba(7, 138, 81, 0.5)',
                              color: '#078A51',
                              display: 'flex',
                              flexWrap: 'nowrap',
                              flexShrink: '0',
                              margin: '0 0 0 1.5rem'
                            }}
                            disabled={isLoadingExportToCsv}
                            onClick={() => requestCsvExport(formik.values)}
                            startIcon={<FileDownloadIcon />}>
                            Eksport do CSV
                          </StyledButton>
                          <CustomTooltip text={tooltipExportText} />
                        </>
                      )}
                    </Header>
                  </div>
                )}
                <TableContainer>
                  <Scrollbar>
                    <Table className={tableClasses.table} aria-labelledby="tableTitle" size="medium">
                      <TableHead
                        headCells={headCells}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        <tr style={{ background: '#FAFAFA', boxShadow: 'inset 0px -1px 0px #E0E0E0' }}>
                          <td></td>
                          {fields.map((field, index) => {
                            switch (field.name) {
                              case '':
                                return <td key={index} />;
                              case 'separator':
                                return <td key={index} colSpan={field.separatorValue}></td>;
                              default:
                                return (
                                  <SearchField
                                    containerWidth={120}
                                    key={field.name}
                                    name={field.name}
                                    value={field.value}
                                    onChange={formik.handleChange}
                                    onKeyDown={() => handleEnterPress(field.name as CUSTOMER_FIELDS)}
                                    onClearValue={() => handleClearValue(field.name)}
                                    hasError={field.error}
                                    minWidth={field.width ?? 100}
                                    helperText={field.helperText}
                                    onClick={() => handleSearch(field.name as CUSTOMER_FIELDS)}
                                  />
                                );
                            }
                          })}
                        </tr>
                        {rows &&
                          rows.map((row) => {
                            return (
                              <TableRow hover tabIndex={-1} key={row.id}>
                                <TableCells
                                  row={row}
                                  setMonitoringTaxId={setMonitoringTaxId}
                                  handleCheckboxClick={handleCheckboxClick}
                                  handleCustomerCheckbox={handleCustomerCheckbox}
                                  isCheck={isCheck}
                                />
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>

                    {customersData?.results?.length === 0 && (
                      <Typography
                        variant="body1"
                        sx={{
                          textAlign: 'center',
                          margin: '3.5rem 0',
                          fontSize: '1..em',
                          color: 'rgba(0, 0, 0, 0.6)'
                        }}>
                        Brak wyników wyszukiwania, zmień kryteria wyszukiwania, wyczyść
                        <br />
                        filtry lub{' '}
                        <Box
                          sx={{ color: '#078A51', textDecoration: 'underline' }}
                          component={Link}
                          to={PATH.ADD_CUSTOMER}
                          target="_blank"
                          rel="noopener noreferrer">
                          dodaj nowego klienta
                        </Box>
                      </Typography>
                    )}
                  </Scrollbar>
                </TableContainer>
                <Pagination
                  extraData={customersData?.totalSize ? `${customersData.totalSize} klientów` : ''}
                  total={customersData?.totalSize || 1}
                  pageSize={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </Paper>
        </Box>
        <Dialog
          isOpen={isSelectedDisable}
          closeModal={cancelActions}
          accept={handleAcceptActions}
          acceptLabel="Wyłącz monitorowanie"
          closeLabel="Anuluj">
          <Box sx={{ display: 'flex', margin: '0 0 1rem 0', fontWeight: 'bold', justifyContent: 'center' }}>
            Czy chcesz wyłączyć monitorowanie?
          </Box>
          Wykonanie tej akcji spowoduje zaprzestanie monitorowania zdarzeń w KRD
          <br />
          i jednocześnie zaprzestanie aktualizowania informacji o ocenie <br />
          wiarygodności płatniczej.
          <Box sx={{ margin: '2rem 0 1rem 0' }}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  disablePast
                  label="Wybierz datę wyłączenia"
                  inputFormat="DD-MM-YYYY"
                  value={date}
                  mask={'__-__-____'}
                  onChange={(value) => handleDateChange(value)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </Box>
        </Dialog>
        <Dialog
          isOpen={isExportModalVisible}
          closeModal={closeExportModal}
          accept={handleAcceptActions}
          acceptLabel=""
          closeLabel="Zamknij">
          <Box sx={{ display: 'flex', margin: '0 0 1rem 0', fontWeight: 'bold', justifyContent: 'center' }}>
            Rozpoczęcie eksportu danych do pliku csv
          </Box>
          Plik csv z wyeksportowanymi danymi z ekranu "Lista klientów" będzie dostępny w zakładce menu "Eksport danych".
          <br />
          Otrzymasz powiadomienie email, gdy plik będzie gotowy do pobrania.
          <Box sx={{ margin: '2rem 0 1rem 0' }}></Box>
        </Dialog>
      </Box>
    </form>
  );
};

export default CustomersList;
