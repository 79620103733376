import { StyledButton } from 'components';
import { FC } from 'react';
import { Tooltip } from 'components';

export type ButtonWithTooltipProps = {
  tooltipOptions?: { active: boolean; text?: string; noIcon: boolean };
} & React.ComponentProps<typeof StyledButton>;

const ButtonWithTooltip: FC<ButtonWithTooltipProps> = (props) => {
  const { tooltipOptions, ...rest } = props;

  const { text, active, noIcon } = tooltipOptions || {};

  const button = <StyledButton {...rest} />;

  return active ? (
    <Tooltip text={text!} noIcon={noIcon}>
      {button}
    </Tooltip>
  ) : (
    button
  );
};

export default ButtonWithTooltip;
