import { FC, useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { ApiResponse, LatestReportsDto, ReportDetailsDto, REPORT_STATE } from 'api/models';
import { addReportRequest, getLatestCompanyReports, getReportFile } from 'api/services';
import moment from 'moment';
import { delay, downloadReport } from 'lib/utils';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAuth } from 'oidc-react';
import { useDispatch } from 'react-redux';
import { reportCounterActions } from 'store/reportCounter';
import { useFetch, useSendRequest } from 'lib/hooks';
import MuiTooltip from '@mui/material/Tooltip';
import styles from './Report.module.scss';
import { CircularProgress, Tooltip } from '@mui/material';
import { ROLE } from 'config/oidc';
import { PATH } from 'router/routes';
import { ReportDownloadIcon, ReportDownloadedTodayIcon, TickDownloaded } from 'assets/images/icons';
import { useSelector } from 'react-redux';
import { connectionSelector } from 'store/connection';
import { PushNotification, NotificationType, ReportNotificationData } from '../../../../App';

const tooltipMessage =
  'Trwa generowanie raportu. Może to potrwać 30 sekund. Pobieramy informacje z wielu źródeł. Po tym czasie kliknij w ikonkę z trzema kropkami aby pobrać wygenerowany raport.';

const RootGenerateNew = styled(ButtonUnstyled)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  background: rgba(41, 167, 69, 0.05);
  border: 1px solid rgba(41, 167, 69, 0.25);f
  border-radius: 4px;
  margin: 0 12px 0 0;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 4px;

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const RootGenerateNewDownloaded = styled(ButtonUnstyled)`
  position: relative;
  border: none;
  margin: 0 12px 0 0;

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const RootDownloadReport = styled('span')`
  display: flex;
  padding: 4px 10px;
  background: rgba(7, 138, 81, 0.05);
  border: 1px solid rgba(7, 138, 81, 0.25);
  border-radius: 4px;
  flex-shrink: 0;
  align-items: center;
  cursor: pointer;
`;

const tooltipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#fff',
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: '0px 0px 24px #00000034;',
          fontSize: '0.9rem',
          maxWidth: 'none',
          padding: '.5rem 0',
          borderRadius: '7px'
        },
        arrow: {
          color: 'rgba(255, 255, 255, 1)',
          '&:before': {
            border: '1px solid rgba(255, 255, 255, 1)'
          }
        }
      }
    }
  }
});

export type ReportProps = {
  taxIdentificationNumber: string;
  Report?: number;
  reportGeneretedAt?: string;
  state?: REPORT_STATE;
  companyId: string;
  id: number;
  counterpartyName: string;
};

const Report: FC<ReportProps> = ({
  Report: report,
  reportGeneretedAt,
  state,
  taxIdentificationNumber,
  companyId,
  id,
  counterpartyName
}) => {
  const [availableReportsCount, setAvailableReportsCount] = useState<number>(0);
  const [latestReports, setLatestReports] = useState<ReportDetailsDto[]>();
  const [reportState, setReportState] = useState<REPORT_STATE | undefined>(state);
  const [reportId, setReportId] = useState<number | undefined>(report);
  const [reportDate, setReportDate] = useState<string>(reportGeneretedAt || '');
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [open, setOpen] = useState(false);
  const [runFetchLatestReports, setRunLatestReports] = useState(0);
  const [isReportGenerated, setIsReportGenerated] = useState(false);
  const isGeneratedToday = moment(moment().format('YYYY-MM-DD')).isSame(moment(reportGeneretedAt).format('YYYY-MM-DD'));

  const connection = useSelector(connectionSelector);

  useEffect(() => {
    connection?.on('SendNotification', (message: PushNotification<ReportNotificationData>) => {
      if (
        taxIdentificationNumber?.includes(message?.data?.taxIdentificationNumber) &&
        message?.type === NotificationType.CompanyReportGenerated
      ) {
        setIsReportGenerated(true);
        setReportState(REPORT_STATE.CREATED);
        setIsButtonClicked(false);
      }
    });
  }, [connection]);

  const dispatch = useDispatch();
  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;
  const hasReportingPermission = auth?.userData?.profile?.HasClientReportPermission;

  const {
    result: generateReportRequestResult,
    isLoading: isReportGenereting,
    sendData: sendGenerateReportRequest
  } = useSendRequest();

  const { isLoading: isLoadingLatestReports } = useFetch<LatestReportsDto>(
    useCallback(async () => {
      const data = await getLatestCompanyReports(companyId, taxIdentificationNumber);
      //@ts-ignore
      setLatestReports(data?.data?.reports);
      //@ts-ignore
      setAvailableReportsCount(data?.data?.availableReportsCount);
      return data;
    }, [companyId, taxIdentificationNumber]),
    [runFetchLatestReports],
    !runFetchLatestReports ? true : false
  );

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    setRunLatestReports(runFetchLatestReports + 1);
  };

  const generateReportRequestCallback = useCallback(async () => {
    try {
      setIsReportGenerated(false);
      const data = generateReportRequestResult as ApiResponse<number>;
      setReportState(1);
      setReportId(Number(data?.Result));
      await delay(90000);
      const getData = await getReportFile(String(data?.Result));
      if (getData.status === 200) {
        setReportState(2);
        setReportDate('');
        dispatch({ type: reportCounterActions.increment });
      }
    } catch (error) {
      //@todo inny task, do ustalenia
    }

    setIsButtonClicked(false);
  }, []);

  const generateReport = async () => {
    setIsButtonClicked(true);
    sendGenerateReportRequest(
      () => addReportRequest(companyId, taxIdentificationNumber, counterpartyName),
      generateReportRequestCallback
    );
  };

  const generateNewReport =
    hasReportingPermission === 'True' ? (
      isReportGenerated || isGeneratedToday ? (
        <ButtonUnstyled
          component={RootGenerateNewDownloaded}
          disabled={isButtonClicked || isReportGenereting}
          onClick={generateReport}>
          <ReportDownloadedTodayIcon />
          <TickDownloaded className={styles.tickIcon} />
        </ButtonUnstyled>
      ) : (
        <ButtonUnstyled
          component={RootGenerateNew}
          variant="contained"
          onClick={generateReport}
          sx={{ position: 'relative' }}
          disabled={isButtonClicked || isReportGenereting}>
          <ReportDownloadIcon color={'#078A51'} />
        </ButtonUnstyled>
      )
    ) : null;

  const reportPending = (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
        <Box
          sx={{
            display: 'flex',
            flexShrink: '0',
            alignItems: 'center',
            margin: '0 12px 0 0',
            width: '36px',
            justifyContent: 'center'
          }}>
          <Tooltip title={tooltipMessage}>
            <HourglassEmptyIcon fontSize="medium" sx={{ color: '#ec9514' }} />
          </Tooltip>
        </Box>
      </Box>
    </>
  );

  const reportList = isLoadingLatestReports ? (
    <CircularProgress></CircularProgress>
  ) : (
    <>
      {latestReports?.map((item, index) => (
        <div
          onClick={() => downloadReport(item.id, item.generatedAt)}
          key={item?.id}
          className={`${styles.item} ${index === 0 ? styles.itemFirst : ''}`}>
          Raport z {moment(item?.generatedAt ? item.generatedAt : undefined).format('DD-MM-YYYY')}
        </div>
      ))}
      {availableReportsCount ? (
        availableReportsCount > 3 ? (
          <Link
            to={`${PATH.CUSTOMER_PROFILE}/${id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '3.25rem',
              whiteSpace: 'nowrap'
            }}>
            <div className={styles.showMore}>Więcej...</div>
          </Link>
        ) : null
      ) : (
        <div className={styles.noItems}>Brak raportów</div>
      )}
    </>
  );

  const reportsList = (
    <ThemeProvider theme={tooltipTheme}>
      <MuiTooltip arrow={true} title={reportList} placement={'bottom'} onClose={handleTooltipClose} open={open}>
        <MoreVertIcon sx={{ cursor: 'pointer' }} onClick={handleTooltipOpen} />
      </MuiTooltip>
    </ThemeProvider>
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem', whiteSpace: 'nowrap' }}>
      {reportState === REPORT_STATE.PENDING ? reportPending : generateNewReport}
      {userRole !== ROLE.CURATOR && reportsList}
    </Box>
  );
};

export default Report;
