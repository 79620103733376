import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Paper,
  Breadcrumbs,
  PageTitle,
  PageSubtitle,
  Switch,
  Table,
  Loader,
  Dialog,
  Tooltip,
  NoDataLabel,
  StyledButton,
  StyledTextField,
  CheckMonitorDialog
} from 'components';
import { PATH } from 'router/routes';
import { createRowData, delay } from 'lib/utils';
import {
  ApiResponse,
  CustomerDetailsDto,
  monitoringEvent,
  MONITORING_STATE,
  ReportDetailsDto,
  REPORT_STATE,
  SourceTypes,
  SCORING_STATE
} from 'api/models';
import {
  getCustomer,
  removeCustomer,
  addReportRequest,
  getReportFile,
  disableMonitoring,
  monitoringEvents,
  enableMonitoring
} from 'api/services';
import { useToggle, useSendRequest, useFetch, useCheckMonitoring } from 'lib/hooks';
import { ROLE } from 'config/oidc';
import { PaymentCredibility, StatusKrd, TradeCreditLimit } from '../shared';
import { ACTION_TEXT_OPTIONS } from '../customersList/customersList.types';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import DownloadableReportItem from './DownloadableReportItem';
import { useDispatch } from 'react-redux';
import { reportCounterActions } from 'store/reportCounter';
import SideNav from 'components/SideNav';
import classes from '../../../App.module.scss';
import SaleBox from './components/SaleBox';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { IMPORT_TYPE } from 'domains/csv/csvList/csvList.types';
import { Tooltip as MuiTooltip } from '@mui/material';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { PushNotification, NotificationType, ReportNotificationData } from '../../../App';
import { useSelector } from 'react-redux';
import { connectionSelector } from 'store/connection';
import { ButtonWithTooltip } from 'components';

const tooltipSourceText = 'Określa, w jaki sposób klient został dodany do aplikacji (np. ręcznie).';
const tooltipRemoveClientText =
  'Usunięcie klienta spowoduje usunięcie danych na jego temat oraz wyłączenie monitorowania (jeśli jest włączone).';
const tooltipReportText =
  'Sekcja zawiera raporty o kliencie pobrane dotychczas przez wszystkich użytkowników w Twojej firmie w ostatnich 7 dniach. Jeśli chcesz wygenerować aktualny raport o tym kliencie, kliknij "Pobierz nowy raport".';
const tooltipMonitoringText =
  'Sekcja zawiera regularnie odświeżane informacje o kliencie pochodzące z KRD (jeśli monitorowanie jest włączone).';
const tooltipTradeCreditLimitText =
  'Prezentowana wysokość limitu kupieckiego jest maksymalnym możliwym zobowiązaniem wobec dostawcy towarów i usług z odroczonym terminem płatności, przy średniej rotacji na poziomie 60 dni oraz przy założeniu, że jest do 5 dostawców oferujących płatność na podobnych parametrach w tym samym czasie. Kwota stanowi jedynie rekomendację i nie jest decyzją kredytową.';
const tooltipPaymentCredibilityText =
  'Kategoria wiarygodności płatniczej wynika z oceny prawdopodobieństwa wpisania klienta do KRD.';
const tooltipSalesText =
  'Dane te są importowane do aplikacji za pomocą pliku CSV. Data w nawiasie oznacza datę importu danych.';
export const tooltipImportInProgress =
  'Obecnie nie można wykonać tej operacji, ponieważ jeden z użytkowników w Twojej firmie importuje dane dotyczące tego klienta. Spróbuj ponownie za około 10 minut.';

const tooltipSourceUrl = { linkUrl: '/tutorial#punkt-2-1', linkName: 'Samouczek, punkt 2.1.' };
const tooltipRemoveClientUrl = { linkUrl: '/tutorial#punkt-7-2', linkName: 'Samouczek, punkt 7.2' };
const tooltipMonitoringUrl = { linkUrl: '/tutorial#punkt-2-4', linkName: 'Samouczek, punkt 2.4' };
const tooltipReportUrl = { linkUrl: '/tutorial#punkt-2-5', linkName: 'Samouczek, punkt 2.5.' };
const tooltipSalesUrl = { linkUrl: '/tutorial#punkt-2-2', linkName: 'Samouczek, punkt 2.2.' };
const tooltipPaymentCredibilityUrl = { linkUrl: '/tutorial#punkt-2-3', linkName: 'Samouczek, punkt 2.3.' };
const tooltipTradeCreditLimitUrl = { linkName: 'Samouczek, punkt 4.2', linkUrl: '/tutorial#punkt-4-2' };
export const tooltipOldData =
  'Uwaga: Dane o tym Kliencie nie były zawarte w ostatnim imporcie danych. Wyświetlane dane pochodzą z importu wykonanego w dniu ';

export const source = new Map([
  [1, 'Dodany ręcznie'],
  [2, 'Import CSV']
]);

const CustomerProfile = () => {
  const [disablingDate, setDisablingDate] = useState<Date | null>(new Date());
  const [isModalOpen, toggleModal] = useToggle();
  const [isMonitoringEnabled, setIsMonitoringEnabled] = useState<boolean>(false);
  const [isMonitoringDisabled, setIsMonitoringDisabled] = useState<boolean>(false);
  const [isMonitoringModalOpen, setIsMonitoringModalOpen] = useState<boolean>(false);
  const [disableMonitoringBlocked, setDisableMonitoringBlocked] = useState<boolean>(false);
  const [havePendingReports, setHavePendingReports] = useState(false);
  const [result, setResult] = useState<CustomerDetailsDto>();
  const [reports, setReports] = useState<ReportDetailsDto[]>([]);
  const [events, setEvents] = useState<monitoringEvent[] | undefined>();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [showDisablingDate, setShowDisablingDate] = useState(false);
  const [earliestDate, setEarliestDate] = useState('');
  const taxId = useMemo(() => result?.taxIdentificationNumber, [result]);
  const connection = useSelector(connectionSelector);

  useEffect(() => {
    connection?.on('SendNotification', (message: PushNotification<ReportNotificationData>) => {
      if (
        taxId?.includes(message?.data?.taxIdentificationNumber) &&
        message?.type === NotificationType.CompanyReportGenerated
      ) {
        setReports([
          {
            id: message?.data?.reportRequestId,
            state: REPORT_STATE.CREATED,
            generatedAt: message?.data?.reportGeneratedAt
          },
          ...reports.filter((el) => el.generatedAt)
        ]);
        setIsButtonClicked(false);
        setHavePendingReports(false);
      }
    });
  }, [reports, taxId, connection]);

  const curatorSelectedCompanyId = localStorage.getItem('curatorSelectedCompanyId');
  const dispatch = useDispatch();
  const { id } = useParams();
  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;
  const companyId =
    userRole === ROLE.CURATOR && curatorSelectedCompanyId ? curatorSelectedCompanyId : auth?.userData?.profile?.Company;
  const hasMonitoringPermission = auth?.userData?.profile?.HasClientMonitoringPermission;
  const hasReportingPermission = auth?.userData?.profile?.HasClientReportPermission;

  const { canEnable, canDisable, isOpen, cancelAction, openModal } = useCheckMonitoring();

  const { isLoading } = useFetch<CustomerDetailsDto>(
    useCallback(async () => {
      const data = await getCustomer(id || '');
      const { Result: res } = data.data;
      setResult(res);
      setReports(res.reportDetails);
      setIsMonitoringEnabled(res?.monitoringSummary?.monitoringState ? true : false);
      if (res.monitoringSummary) {
        const getEvents = await monitoringEvents(res.monitoringSummary.monitoringId);
        setEvents(getEvents?.data?.Result?.monitoringEvents);
      }
      return data;
    }, [id])
  );

  const { isLoading: isDeleteLoading, sendData } = useSendRequest();
  const { result: generateReportRequestResult, sendData: sendGenerateReportRequest } = useSendRequest();

  const breadcrumbs = [{ to: PATH.CUSTOMERS_LIST, label: 'Klienci' }, { label: result?.name || '' }];

  const handleDateChange = (newValue: Date | null) => {
    setDisablingDate(newValue);
  };

  useEffect(() => {
    const containsPendingReports =
      !!reports &&
      reports?.some(function (x: any) {
        return x.state === REPORT_STATE.PENDING;
      });
    setHavePendingReports(containsPendingReports);

    if (containsPendingReports) {
      const updateStatus = async () => {
        await delay(90000);
        const currentReports = [...reports];
        const [first] = currentReports;
        const getData = await getReportFile(String(first.id));

        if (getData.status === 200) {
          currentReports[0].state = 2;
          delete currentReports[0].generatedAt;
          setReports(currentReports);
        }
      };

      updateStatus();
    }
  }, [reports]);

  const sourceText = (id: number = 0) => {
    return source.get(id);
  };

  const clientData = [
    createRowData('Nazwa klienta', result?.name),
    createRowData('NIP', result?.taxIdentificationNumber?.replace('PL', '')),
    createRowData('Opis 1', result?.description1),
    createRowData('Opis 2', result?.description2),
    createRowData(
      'Ulica',
      `${result?.street ? result?.street : ''} ${result?.buildingNumber ? result?.buildingNumber : ''}${
        result?.apartmentNumber ? `, lokal ${result?.apartmentNumber}` : ''
      }`
    ),
    createRowData('Kod pocztowy', result?.postcode),
    createRowData('Miejscowość', result?.city),
    createRowData(
      'Źródło',
      sourceText(result?.source),
      <Tooltip text={tooltipSourceText} link={tooltipSourceUrl}></Tooltip>
    )
  ];

  const submitCallback = useCallback(() => {
    window.close();
  }, []);

  const deleteHandler = useCallback(async () => {
    if (result?.monitoringSummary?.monitoringId) {
      await disableMonitoring(companyId, [result.monitoringSummary.monitoringId], disablingDate);
    }
    return await removeCustomer(id || '');
  }, [id]);
  const deleteClient = () => sendData(deleteHandler, submitCallback, toggleModal);

  const generateReportRequestCallback = useCallback(async () => {
    try {
      const data = generateReportRequestResult as ApiResponse<number>;
      const currentReports = [...reports];
      const reportId = Number(data?.Result);
      const newReport = [{ id: reportId, state: REPORT_STATE.PENDING }];
      const setData = [...newReport, ...currentReports];

      setReports(setData);
      await delay(90000);

      const getData = await getReportFile(String(data?.Result));
      const updatedReport = [{ id: reportId, state: REPORT_STATE.CREATED }];
      if (getData.status === 200) {
        setReports([...updatedReport, ...currentReports]);
        dispatch({ type: reportCounterActions.increment });
      }
    } catch (error) {
      //@todo inny task, do ustalenia
    }

    setIsButtonClicked(false);
  }, [reports]);

  const generateReport = async (taxNumber: string, counterpartyName: string) => {
    setIsButtonClicked(true);
    sendGenerateReportRequest(
      () => addReportRequest(companyId, taxNumber, counterpartyName),
      generateReportRequestCallback
    );
  };

  const monitoringResult = () => {
    if (result?.scoring?.state === SCORING_STATE.ERROR_GETTING_DEBT) {
      return (
        <Box sx={{ padding: '1rem 1.5rem 0 1.5rem', maxWidth: '45.7rem', width: '100%' }}>
          <NoDataLabel
            text={
              'Brak uprawnień do pobrania informacji o kategorii wiarygodności płatniczej. Skontaktuj się z Administratorem KG.'
            }></NoDataLabel>
        </Box>
      );
    } else if (
      result?.scoring?.state === SCORING_STATE.ERROR_ENABLING ||
      monitoringState === MONITORING_STATE.ERROR_ENABLING
    ) {
      return (
        <Box sx={{ padding: '1rem 1.5rem 0 1.5rem', maxWidth: '45.7rem', width: '100%' }}>
          <NoDataLabel
            text={'Pobranie informacji nie powiodło się. Skontaktuj się z Administratorem KG.'}></NoDataLabel>
        </Box>
      );
    } else if (
      result?.monitoringSummary?.monitoringState === MONITORING_STATE.ENABLED &&
      result?.scoring?.state === SCORING_STATE.ERROR_DISABLING
    ) {
      return (
        <Box sx={{ padding: '1rem 1.5rem 0 1.5rem', maxWidth: '45.7rem', width: '100%' }}>
          <NoDataLabel text={'Ponawianie próby pobrania informacji.'}></NoDataLabel>
        </Box>
      );
    } else if (
      result?.monitoringSummary?.monitoringState === MONITORING_STATE.ERROR_DISABLING ||
      result?.scoring?.state === SCORING_STATE.ERROR_DISABLING
    ) {
      return (
        <Box sx={{ padding: '1rem 1.5rem 0 1.5rem', maxWidth: '45.7rem', width: '100%' }}>
          <NoDataLabel
            text={'Nie udało się wyłączyć monitorowania. Skontaktuj się z Administratorem KG.'}></NoDataLabel>
        </Box>
      );
    } else if (result?.monitoringSummary?.monitoringState === MONITORING_STATE.DISABLING) {
      return (
        <Box sx={{ padding: '1rem 1.5rem 0 1.5rem', maxWidth: '45.7rem', width: '100%' }}>
          <NoDataLabel text={'Trwa wyłączanie monitorowania.'}></NoDataLabel>
        </Box>
      );
    } else if (
      result?.monitoringSummary?.monitoringState === MONITORING_STATE.CREATED ||
      result?.monitoringSummary?.monitoringState === MONITORING_STATE.ADDED_IN_MPRO ||
      (result?.monitoringSummary?.monitoringState === MONITORING_STATE.ENABLED &&
        result?.scoring?.state === SCORING_STATE.CREATED) ||
      (result?.monitoringSummary?.monitoringState === MONITORING_STATE.ENABLED &&
        result?.scoring?.state === SCORING_STATE.ENABLED &&
        (result.scoring.riskGroupCode === null || result.scoring.riskGroupCode === undefined))
    ) {
      const message = result?.monitoringSummary?.isRetry
        ? 'Ponawianie próby pobrania informacji.'
        : 'Trwa pobieranie informacji. Informacja pojawi się do 2 godzin od zlecenia włączenia monitorowania.';

      return (
        <Box sx={{ padding: '1rem 1.5rem 0 1.5rem', maxWidth: '45.7rem', width: '100%' }}>
          <NoDataLabel text={message}></NoDataLabel>
        </Box>
      );
    } else if (result?.scoring?.riskGroupCode && result?.scoring?.scoringStatusCode === '0') {
      return <PaymentCredibility letter={result?.scoring?.riskGroupCode} />;
    } else if (result?.scoring?.scoringStatusCode !== '0' && result?.scoring?.scoringStatus) {
      return (
        <Box sx={{ padding: '1rem 1.5rem 0 1.5rem', maxWidth: '45.7rem', width: '100%' }}>
          <NoDataLabel text={`Brak kategorii - ${result?.scoring?.scoringStatus}.`}></NoDataLabel>
        </Box>
      );
    } else return null;
  };

  const reportItems = !!reports?.length ? (
    reports?.map((x, index) => <DownloadableReportItem key={x.id} item={x} index={index + 1}></DownloadableReportItem>)
  ) : (
    <NoDataLabel text={'Brak raportów do pobrania.'}></NoDataLabel>
  );

  const onMonitoringChangeDisable = () => setIsMonitoringModalOpen(true);

  const onMonitoringChangeEnable = (earliestDate: string) => {
    if (result?.taxIdentificationNumber) {
      enableMonitoring(companyId, [result.taxIdentificationNumber], earliestDate);
      setIsMonitoringEnabled(true);
      setDisableMonitoringBlocked(true);
    }
  };

  const onMonitoringChange = async () => {
    if (isMonitoringEnabled) {
      onMonitoringChangeDisable();
    } else {
      const { canEnableMonitoring, earliestDate } = await canEnable();
      setEarliestDate(earliestDate);
      if (canEnableMonitoring) {
        onMonitoringChangeEnable(earliestDate);
      } else {
        openModal();
      }
    }
  };

  const accept = () => {
    if (isMonitoringEnabled) {
      disableMonitoringService(earliestDate);
    } else {
      onMonitoringChangeEnable(earliestDate);
    }
    cancelAction();
  };

  const disableMonitoringService = async (earliestDate?: string) => {
    if (result?.monitoringSummary?.monitoringId) {
      try {
        const disable = await disableMonitoring(
          companyId,
          [result.monitoringSummary.monitoringId],
          earliestDate ?? disablingDate
        );
        setIsMonitoringModalOpen(false);
        setIsMonitoringEnabled(false);
        setIsMonitoringDisabled(true);
        if (disable.status === 200) {
          setShowDisablingDate(true);
        }
      } catch {
        setIsMonitoringModalOpen(false);
      }
    }
  };

  const handleDisableMonitoring = async () => {
    const dateIsToday = moment(disablingDate).isSame(moment(), 'day');
    if (dateIsToday) {
      const { canDisableMonitoring, earliestDate } = await canDisable();
      setEarliestDate(earliestDate);
      if (canDisableMonitoring) {
        disableMonitoringService();
      } else {
        setIsMonitoringModalOpen(false);
        openModal();
      }
    } else {
      disableMonitoringService();
    }
  };

  const monitoringState = result?.monitoringSummary?.monitoringState;

  const disablingDateIsSameOrAfter = moment(moment(disablingDate).format('YYYY-MM-DD')).isSameOrAfter(
    moment().format('YYYY-MM-DD')
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet>
        <title>RiskRadar - {result?.name ?? ''}</title>
      </Helmet>
      <CheckMonitorDialog isOpen={isOpen} cancelAction={cancelAction} accept={accept} />
      <Box className={classes.sidebar}>
        <SideNav />
      </Box>
      <Paper fullSize>
        <Loader isLoading={isLoading}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />

          <Box sx={{ padding: '1.5rem 0 0.5rem 0' }}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ margin: '0 1.5rem', width: '50%' }}>
                <PageTitle>Dane klienta</PageTitle>
                <Box sx={{ marginTop: '1rem' }}>
                  <Table
                    style={{
                      borderRadius: '3px',
                      borderCollapse: 'collapse',
                      borderStyle: 'hidden',
                      boxShadow: '0 0 0 1px #e0e0e0'
                    }}
                    rows={clientData}
                    label="dane klienta"
                    boldValues
                  />
                </Box>
              </Box>
            </Box>

            <Stack spacing={3} direction="row" sx={{ padding: '1.5rem 1.5rem 0 1.5rem', textTransform: 'uppercase' }}>
              <ButtonWithTooltip
                tooltipOptions={{
                  active: !result?.isEditable!,
                  text: tooltipImportInProgress,
                  noIcon: true
                }}
                sx={{ background: '#078A51' }}
                variant="contained"
                disabled={result?.source === IMPORT_TYPE.Import_CSV || !result?.isEditable!}
                //@ts-ignore //@todo
                component={Link}
                to={`${PATH.EDIT_CUSTOMER}/${id}`}>
                Modyfikuj
              </ButtonWithTooltip>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <ButtonWithTooltip
                  tooltipOptions={{
                    active: !result?.isEditable!,
                    text: tooltipImportInProgress,
                    noIcon: true
                  }}
                  variant="outlined"
                  color="error"
                  onClick={toggleModal}
                  disabled={userRole === ROLE.ADMIN_KG || !result?.isEditable!}
                  startIcon={<DeleteIcon color="error" />}>
                  Usuń klienta
                </ButtonWithTooltip>
                <Tooltip text={tooltipRemoveClientText} link={tooltipRemoveClientUrl} />
              </Box>
            </Stack>
          </Box>

          <Box sx={{ padding: '1.5rem 0 1rem 0' }}>
            <Box
              sx={{
                display: 'flex',
                borderTop: '1px solid #E0E0E0'
              }}>
              <Box sx={{ marginTop: '1.5rem', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PageSubtitle sx={{ padding: '0 0 0 1.5rem' }} subtitle="Wiarygodność płatnicza" />
                  <Tooltip text={tooltipPaymentCredibilityText} link={tooltipPaymentCredibilityUrl} />
                </Box>
                <Box sx={{ width: '100%' }}>
                  {result?.monitoringSummary === null ||
                  result?.monitoringSummary?.monitoringState === MONITORING_STATE.DISABLED ? (
                    <Box
                      sx={{
                        padding: '1rem 1.5rem',
                        borderRadius: '3px',
                        margin: '1.5rem 0 0 1.5rem',
                        textAlign: 'center',
                        border: '1px solid #000',
                        maxWidth: '42.2rem',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        fontSize: '1rem'
                      }}>
                      <Box
                        sx={{
                          background: '#000',
                          borderRadius: '50%',
                          width: '3rem',
                          height: '3rem',
                          marginRight: '1rem',
                          color: '#fff',
                          fontSize: '2rem',
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex'
                        }}>
                        -
                      </Box>
                      Monitorowanie wyłączone
                    </Box>
                  ) : (
                    ''
                  )}
                  {monitoringResult()}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ padding: '1.5rem 0 2rem 0' }}>
            <Box
              sx={{
                display: 'flex',
                borderTop: '1px solid #E0E0E0'
              }}>
              <Box sx={{ marginTop: '1.5rem', width: '100%', maxWidth: '44.7rem' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PageSubtitle sx={{ padding: '0 0 0 1.5rem' }} subtitle="Monitorowanie" />
                  <Tooltip text={tooltipMonitoringText} link={tooltipMonitoringUrl} />
                </Box>
                {[ROLE.CLIENT_USER, ROLE.CLIENT_ADMIN, ROLE.CURATOR].includes(userRole) &&
                  hasMonitoringPermission === 'True' && (
                    <Box
                      sx={{
                        margin: '1rem 0 0 -10px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%'
                      }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <Switch
                          sx={{ padding: '0 .5rem 0 1.5rem' }}
                          label="Monitorowanie klienta"
                          isChecked={
                            !(
                              monitoringState === MONITORING_STATE.DISABLING ||
                              monitoringState === MONITORING_STATE.DISABLED
                            ) && isMonitoringEnabled
                          }
                          disabled={
                            monitoringState !== MONITORING_STATE.ENABLED ||
                            isMonitoringDisabled ||
                            disableMonitoringBlocked ||
                            !result?.isEditable!
                          }
                          tooltipActive={!result?.isEditable!}
                          tooltipText={tooltipImportInProgress}
                          onChange={onMonitoringChange}
                        />
                        {!isMonitoringEnabled ||
                        monitoringState === MONITORING_STATE.DISABLING ||
                        monitoringState === MONITORING_STATE.DISABLED
                          ? ACTION_TEXT_OPTIONS.Enable
                          : ACTION_TEXT_OPTIONS.Disable}
                      </Box>
                      {(monitoringState === MONITORING_STATE.DISABLING ||
                        monitoringState === MONITORING_STATE.DISABLED ||
                        showDisablingDate ||
                        (monitoringState === MONITORING_STATE.ENABLED &&
                          result?.monitoringSummary?.monitoringEndDate)) && (
                        <Box sx={{ color: '#EA9617', whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                          Wyłączenie monitorowania nastąpi{' '}
                          {moment(
                            showDisablingDate && earliestDate
                              ? earliestDate
                              : showDisablingDate && disablingDate
                              ? disablingDate
                              : result?.monitoringSummary?.monitoringEndDate
                          ).format('DD-MM-YYYY')}
                          <WarningAmberIcon sx={{ marginLeft: '0.5rem' }} />
                        </Box>
                      )}
                    </Box>
                  )}
              </Box>
            </Box>
            <Box sx={{ padding: '1rem 1.5rem 0 1.5rem', width: '100%', maxWidth: '44.7rem' }}>
              <StatusKrd data={result?.monitoringSummary} monitoringEvents={events}></StatusKrd>
            </Box>
          </Box>

          <Box sx={{ padding: '1.5rem 0 2rem 0', borderBottom: '1px solid #E0E0E0' }}>
            <Box
              sx={{
                display: 'flex',
                borderTop: '1px solid #E0E0E0'
              }}>
              <Box sx={{ marginTop: '1.5rem', width: '100%', maxWidth: '44.7rem' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PageSubtitle sx={{ padding: '0 0 0 1.5rem' }} subtitle="Limit kupiecki" />
                  <Tooltip text={tooltipTradeCreditLimitText} link={tooltipTradeCreditLimitUrl} />
                </Box>
              </Box>
            </Box>
            <TradeCreditLimit
              tradeCreditLimit={result?.tradeCreditLimit}
              tradeCreditLimitAuthorizationErrorState={result?.scoring?.tradeCreditLimitAuthorizationErrorState}
              calculatedAt={result?.scoring?.calculatedAt}
              monitoringState={result?.monitoringSummary?.monitoringState}
              scoringState={result?.scoring?.state}
              isRetry={result?.monitoringSummary?.isRetry}
              lastDebtSynchronizationDate={result?.monitoringSummary?.lastDebtSynchronizationDate}
              riskGroupCode={result?.scoring?.riskGroupCode}
            />
          </Box>

          {userRole !== ROLE.CURATOR && (
            <Box sx={{ padding: '0 0 0.75rem 0' }}>
              <Box
                sx={{
                  marginTop: '1.5rem',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  maxWidth: '45.7rem',
                  justifyContent: 'space-between'
                }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PageSubtitle sx={{ padding: '0 0 0 1.5rem' }} subtitle="Raporty" />
                  <Tooltip text={tooltipReportText} link={tooltipReportUrl} />
                </Box>
                <Box sx={{ margin: '0 1.5rem', display: 'flex', alignItems: 'center' }}>
                  {hasReportingPermission === 'True' && (
                    <StyledButton
                      variant="outlined"
                      sx={{ display: 'flex', flexWrap: 'nowrap', flexShrink: '0' }}
                      onClick={() =>
                        result?.taxIdentificationNumber
                          ? generateReport(result.taxIdentificationNumber, result.name ?? '')
                          : null
                      }
                      disabled={havePendingReports || isButtonClicked}>
                      POBIERZ NOWY RAPORT
                    </StyledButton>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  padding: '1rem 1.5rem 0 1.5rem',
                  width: '100%',
                  maxWidth: '45.75rem',
                  maxHeight: '15.7rem',
                  overflow: 'auto'
                }}>
                {reportItems}
              </Box>
            </Box>
          )}

          <Box sx={{ padding: '1.5rem 0 1rem 0' }}>
            <Box
              sx={{
                display: 'flex',
                borderTop: '1px solid #E0E0E0'
              }}>
              <Box sx={{ marginTop: '1.5rem', width: '100%', maxWidth: '44.2rem' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PageSubtitle sx={{ padding: '0 0 0 1.5rem' }} subtitle="Sprzedaż i należności" />
                    <Tooltip text={tooltipSalesText} link={tooltipSalesUrl} />
                  </Box>
                  {result?.source === SourceTypes.Csv && result?.isOutdated && (
                    <Box sx={{ display: 'flex', alignItems: 'end', color: '#EA9617', fontWeight: '500' }}>
                      <MuiTooltip title={`${tooltipOldData} ${moment(result.importDate).format('DD-MM-YYYY')}.`}>
                        <Box sx={{ display: 'flex', alignItems: 'end' }}>
                          Nieaktualne dane <WarningAmberIcon sx={{ marginLeft: '0.5rem' }} />
                        </Box>
                      </MuiTooltip>
                    </Box>
                  )}
                </Box>
                <SaleBox
                  number={result?.grossSaleLast12Months}
                  secondNumber={result?.grossDebt}
                  importDate={result?.importDate}
                  text={'Sprzedaż brutto w PLN'}
                  textColor={'#2360BC'}
                  color={'blue'}
                />
                <SaleBox
                  number={result?.grossDebt}
                  secondNumber={result?.grossSaleLast12Months}
                  importDate={result?.importDate}
                  text={'Należności brutto w PLN'}
                  textColor={'#E52713'}
                  color={'red'}
                />
              </Box>
            </Box>
          </Box>
        </Loader>

        <Dialog
          isOpen={isModalOpen}
          isPending={isDeleteLoading}
          closeModal={toggleModal}
          accept={deleteClient}
          acceptLabel="Tak"
          closeLabel="Nie">
          <Box sx={{ display: 'flex', margin: '0 0 1rem 0', fontWeight: 'bold', justifyContent: 'center' }}>
            Czy chcesz trwale usunąć klienta z aplikacji RiskRadar?
          </Box>
          Usunięcie klienta spowoduje wyłączenie monitorowania tego klienta, w przypadku gdy był on włączony
        </Dialog>

        <Dialog
          isOpen={isMonitoringModalOpen}
          closeModal={() => {
            setIsMonitoringModalOpen(false);
          }}
          accept={handleDisableMonitoring}
          acceptLabel="Wyłącz monitorowanie"
          closeLabel="Anuluj"
          acceptDisabled={!disablingDateIsSameOrAfter}>
          <Box sx={{ display: 'flex', margin: '0 0 1rem 0', fontWeight: 'bold', justifyContent: 'center' }}>
            Czy chcesz wyłączyć monitorowanie?
          </Box>
          Wykonanie tej akcji spowoduje zaprzestanie monitorowania zdarzeń w KRD
          <br />
          i jednocześnie zaprzestanie aktualizowania informacji o ocenie <br />
          wiarygodności płatniczej.
          <Box sx={{ margin: '2rem 0 1rem 0' }}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  disablePast
                  label="Wybierz datę wyłączenia"
                  inputFormat="DD-MM-yyyy"
                  mask={'__-__-____'}
                  value={disablingDate}
                  onChange={(value) => handleDateChange(value)}
                  renderInput={(params) => <StyledTextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </Box>
        </Dialog>
      </Paper>
    </Box>
  );
};

export default CustomerProfile;
